@import "variables";
.root {
	cursor: pointer;
	border-bottom: $border-base;
	padding: 11px 11px 11px $gap-xl;
	transition: all 0.1s ease-in-out;

	&.selected {
		background-color: $gray-5;
	}
	&:hover,
	&.active {
		position: relative;
		background-color: $green-4;
	}

	table {
		tbody {
			tr {
				.table-col {
					padding: 0;
				}
			}
		}
	}
}

.subject {
	:global {
		span {
			white-space: normal;
		}
	}
}

.threadRow {
	:global {
		td.table-col {
			padding: 0;
		}
	}
}

.jobCodeTagItem {
	display: inline-block;
	margin-right: $gap * 0.5;
}

.checkboxSpan {
	margin-left: -18px;
}

.alertSvg {
	position: relative;
	margin-left: -5px;
	top: -1px;
}

.dateSpan {
	position: relative;
	margin-left: -8px;
}

@import "variables";
.root {
	width: 100%;
	height: 100%;
	overflow: hidden;
}

.previewNotAvailable {
	height: 100%;
	padding: $gap*3;
	position: relative;

	img {
		height: 100%;
	}
}

.previewNotAvailableNotification {
	@include bubble-notification($gray-5);
	font-size: $font-size-base;
	padding: 6px $gap;
	position: absolute;
	@extend %transform-center-center;
}

.fullWidth {
	width: 100%;
}

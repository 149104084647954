@import "variables";
$icon-font-size: 6px;

.root {
	display: block;
	:global {
		.ant-select-arrow {
			color: $input-border-color;
		}
		.ant-select-selection__clear {
			background: transparent;
		}
	}

	&:global {
		> .ant-select-selection {
			@extend %input;
		}
		&-disabled {
			@extend %input-disabled;
		}
	}
}

.container {
	width: 100%;

	:global {
		.ant-select-dropdown--multiple {
			.ant-select-dropdown-menu-item {
				&:not(.ant-select-dropdown-menu-item-selected):hover {
					.ant-select-selected-icon {
						color: $gray-4;
					}
				}
			}
		}
		input.ant-select-search__field {
			padding: 1px 0;
		}

		.ant-select-dropdown-menu-item:hover,
		.ant-select-dropdown-menu-item-active {
			background-color: $green-4;
		}
	}
}

@import "variables";
.header {
	display: flex;
	flex-direction: row;
	padding: $gap;
	min-height: 40px;
}

.label {
	margin-right: $gap-md;
}

@import "variables";
.groupsSelector {
	width: 240px;
	max-width: 100%;
}

.root {
	header {
		margin-bottom: $gap;
	}
}

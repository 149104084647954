@import "variables";
.root {
	position: relative;

	iframe {
		position: absolute;
		width: 100%;
		height: 100%;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		border: 0;
	}
}

%input {
	border-color: $input-border-color;

	&:hover,
	&:focus {
		border-color: $input-hover-border-color;
	}

	&:active,
	&:focus {
		box-shadow: 0 0 4px 0 $input-focus-border-color;
	}

	&::placeholder {
		color: $input-placeholder-color;
	}
}

%input-disabled {
	&:hover,
	&:focus {
		border-color: $input-border-color;
	}
}

%checkbox-inner {
	background-color: transparent;
	&:after {
		border-color: $primary-color;
	}

	&,
	*[class*='-checkbox']:hover > & {
		border-color: $input-border-color;
	}
}

@import "variables";
.moreInfo {
	text-decoration: underline;
	text-decoration-color: $border-color-2;
	cursor: pointer;
}

.overlay {
	width: 30vw;
	:global {
		.ant-popover-inner,
		.ant-popover-inner > div {
			display: flex;
			flex-direction: column;
		}
		.ant-popover-inner > div {
			max-height: 30vh;
		}
		.ant-popover-title {
			flex-shrink: 0;
		}
		.ant-popover-inner-content {
			overflow-y: auto;
		}
	}
}

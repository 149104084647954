@import "variables";
.root {
	padding: 0 $gap;
	h1 {
		text-transform: uppercase;
		font-weight: 700;
		color: $table-header-color;
		margin-bottom: 5px;
		font-size: 1rem;
	}
	section {
		padding: $gap 0;
	}
}

.divider {
	background-color: #879aae;
	header {
		padding-top: $gap-sm;
		padding-bottom: 0;
	}

	h2 {
		text-transform: uppercase;
		color: $white;
		font-size: 1rem;
		font-weight: 600;
	}
}

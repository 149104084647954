@import "variables";
%hover {
	background-color: $blue-1;
}

.item {
	cursor: pointer;
	padding: $gap-sm $gap;
	border-bottom: 1px solid $gray-4;
	&:hover {
		@extend %hover;
	}
}

.selected {
	@extend %hover;
}

@import "variables";
.groupsTable {
	tbody > tr {
		cursor: pointer;
		> td {
			&:first-child {
				font-weight: 600;
			}
		}
	}
}

@import "variables";
.root {
	color: $white;
	padding-left: $gap-md;

	:global {
		i.ant-dropdown-trigger {
			padding: $gap;
			color: $white;
			font-size: $font-size-xs;
		}
	}
}

.username {
	text-align: right;
	cursor: pointer;
	line-height: 1.3;
	margin-left: $gap;
}

.usernameContent {
	display: inline-block;
	padding-right: $gap + 2px;
	position: relative;
	width: 100%;
	/** This helps to make truncation work inside */

	&:after {
		content: '';
		width: 2px;
		display: inline-block;
		position: absolute;
		height: 24px;
		background-color: rgba($white, 0.2);
		right: 0;
		top: 50%;
		transform: translateY(-50%);
	}
}

.newButton {
	background-color: #1890ff;
	border-color: #1890ff;
	color: white;
}

.newButtonText {
	color: #1890ff;
}

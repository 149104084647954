@import "variables";
$icon-color: #989ea5;

.root {
	position: relative;
}

.menu {
	position: absolute;
	z-index: 1000;
	top: 100%;
	width: 100%;
	margin-top: 0;
	padding: 0;
	border-radius: 0 0 3px 3px;
	background: $white;
	outline: 0;
	border: 1px solid #64a6e4;
	border-top-color: $gray-6;
	font-size: 12px;
	max-height: 240px;
	overflow-y: auto;
}

.expanded {
	> input {
		border: 1px solid #64a6e4;
		border-bottom: 1px solid $gray-6;
		border-bottom-left-radius: 0;
		border-bottom-right-radius: 0;

		&,
		&:focus {
			border-bottom: 1px solid transparent;
			box-shadow: none;
		}
	}
}

.loading {
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	right: 6px;
	width: auto;

	svg {
		margin-top: 0;
		margin-bottom: 0;
		width: 15px;
		height: 15px;
	}
}

.clearButton {
	z-index: 1001;
	background-color: transparent;
	border: none;
	position: absolute;
	right: 0;
	top: 0;
	cursor: pointer;
	width: 30px;
	height: 100%;
	color: $icon-color;

	&:hover,
	&:focus {
		color: darken($icon-color, 10%);
	}
}

.clearIcon {
	font-weight: 600;
}

.option,
.notFound {
	line-height: $gap-lg;
	padding: $gap-sm $gap;
	color: $black;
}

.option {
	cursor: pointer;
}

.highlighted {
	background-color: $green-4;
}

.optionGroupTitle {
	padding: $gap-sm $gap;
	font-size: $font-size-xs;
	font-weight: 600;
	color: $title-color-1;
	background-color: $background-3;
}

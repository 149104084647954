@import "variables";
.root {
	counter-reset: step;

	&.vertical {
		> li:not(:last-child) {
			margin-bottom: $gap;
		}
	}

	&:not(.vertical) {
		@extend %flex-inline-box;
		> li:not(:last-child) {
			margin-right: $gap-lg;
		}
	}
}

.label {
	color: $tab-heading-color;
	text-transform: uppercase;
	font-weight: $font-weight-bold;
	padding-top: $gap-sm;

	.active & {
		color: $text-color-base;
	}
}

$step-height: 30px;

.step {
	width: $step-height;
	height: $step-height;
	border: 2px solid $border-color-1;
	border-radius: 100%;
	margin-right: $gap;
	font-weight: $font-weight-bold;
	@extend %flex-inline-box, %justify-center, %align-center;

	&:before {
		counter-increment: step;
		content: counter(step);
	}

	.active & {
		color: $white;
		background-color: $background-1;
	}

	.completed & {
		&:before {
			content: '';
		}
	}
}

.description {
	margin-top: $gap-sm;
}

.step,
.label,
.description {
	transition: all $transition-speed ease-in-out;
}

@import "variables";
$notification-icon-size: 24px;

.root {
	:global {
		.ant-notification-notice-icon {
			font-size: $notification-icon-size;
			line-height: normal;
			transform: translateY(-10px);
		}

		.ant-notification-notice-message,
		.ant-notification-notice-description {
			margin-left: $notification-icon-size + $notification-icon-size * 0.5;
		}

		.ant-notification-notice-close {
			right: $gap;
		}
	}
}

.noMessage {
	:global {
		.ant-notification-notice-message {
			display: none;
		}
	}
}

.descriptionList {
	padding-left: $gap-md;
	list-style: initial;
}

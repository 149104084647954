@import "variables";
.root {
	display: flex;
	flex-direction: row;
	align-items: center;

	&:global {
		> a,
		> .ant-btn {
			margin-right: $gap;

			&:last-child:not(:first-child) {
				margin-right: 0;
			}
		}
	}
}

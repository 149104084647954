@import "variables";
.drafts {
	box-shadow: 0 0 8px rgba(0, 0, 0, 0.15);
	border-radius: 0;

	.addOptionMenuItem {
		cursor: auto;
		box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);

		&:hover {
			background: $white;
		}
		> button {
			height: auto;
		}
	}
}

/*
	when the last child is absolute and has bottom shift
	ant select component calculates height of the popup incorrectly
	and it causes improper position of the popup in some cases (bottom instead of top)
	which leads to some bugs (IPP-19581)
*/
.draftSelectContainer {
	width: 100%;
}

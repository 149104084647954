@import "variables";
.root {
	cursor: pointer;
	border-bottom: $border-base;
	padding: 11px 11px 11px $gap-xl;
	transition: all 0.1s ease-in-out;

	&.selected {
		background-color: rgba(153, 197, 237, 0.4);
	}
	&:hover,
	&.active {
		position: relative;
		background-color: $green-4;
	}
}

.about {
	position: relative;
	:global {
		.anticon.anticon-warning {
			position: absolute;
			left: -20px;
			top: 0;
			margin-top: 2px;
		}
	}
}

.updatedOn {
	font-size: $font-size-sm;
	color: $black-1;
}

.title {
	margin-top: 9px;
	margin-bottom: 6px;

	& > h4 {
		display: inline-flex;
	}
}

.jobCodeTagItem {
	display: inline-block;
	margin-right: $gap * 0.5;
}

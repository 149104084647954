@import "variables";
.showCancelledCheckbox {
	bottom: 6px;
	display: flex;
	align-items: center;

	&:global {
		> .ant-checkbox + span {
			padding-left: $checkbox-offset + 2px;
		}
	}
}

.portCallFilterContainer {
	& > div {
		max-width: $gap-lg * 10;
		padding-right: 0;
	}
	& > div.fixedWidth {
		max-width: $gap-lg * 6;

		:global {
			.ant-checkbox {
				top: $gap-sm;
			}
		}

		span:last-child {
			line-height: $gap-xs * 4;
		}
	}
}

.iconButton {
	position: sticky;
	left: 202px;
	top: 26px;
	color: $gray-9;
}

.filterButton {
	font-weight: $font-weight-bold;
	text-transform: none;
	padding: 13px 8px;
	color: $tab-heading-color;
	border: none;
	background-color: $white;
	cursor: pointer;
}

.menu {
	:global {
		.ant-menu-item {
			height: 20px;
			line-height: 20px;
		}
		.ant-menu-item-selected {
			background-color: $white !important;
		}
	}
}

.marginTop {
	margin-top: $gap-sm;
	margin-bottom: -$gap;
}

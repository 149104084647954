@import "variables";
.comment {
	&:not(:last-child) {
		margin-bottom: 10px;
	}
}

.commentContent {
	overflow: auto;
	background: $white;
	padding: $gap;
	margin: $gap-sm 0;
}

.commentBoxInput {
	border-color: $input-placeholder-color;
}

.sendButton {
	margin: $gap-sm 0;
}

.userName {
	color: $title-color-1;
}

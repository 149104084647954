@import "variables";
.backButton {
	display: flex;
	align-items: center;
}

.cancelButton {
	&:global.ant-btn {
		padding: 0 $gap;
	}
}

.subTitle {
	margin-top: $gap;
}

.subHeader {
	height: 30px;
	border-radius: 0;
	border: none;
	font-size: $font-size-sm;
	font-weight: 600;
	color: $table-header-color;
}

.table {
	table-layout: fixed;
	position: relative;
	margin-top: $gap;
}

.header {
	height: $table-row-height;
	line-height: 1em;
	background: $table-header-dark-bg;
	&:global {
		> td {
			@include make-gutter(column);
			padding-right: 0;
		}
	}
	:global(.ant-badge) {
		margin-left: $gap-xl;
	}
}

.tag {
	border: 1px solid #445564;
	background-color: $white;
	border-radius: 2px;
	font-size: $font-size-xs;
	line-height: 1;
	padding: 4px 5px;
}

@import "variables";
.root {
	display: inline-block;
	&.outside {
		/**
			@example
			.topXs { margin-top: $gap-xs; }
			.topSm { margin-top: $gap-sm; }
			.topMd { margin-top: $gap-md; }
			.topLg { margin-top: $gap-lg; }

			...
		 */

		&.topDefault {
			margin-top: $gap;
		}
		&.topSm {
			margin-top: $gap-sm;
		}
		&.topMd {
			margin-top: $gap-md;
		}
		&.topLg {
			margin-top: $gap-lg;
		}

		&.rightDefault {
			margin-right: $gap;
		}
		&.rightSm {
			margin-right: $gap-sm;
		}
		&.rightMd {
			margin-right: $gap-md;
		}
		&.rightLg {
			margin-right: $gap-lg;
		}

		&.bottomDefault {
			margin-bottom: $gap;
		}
		&.bottomSm {
			margin-bottom: $gap-sm;
		}
		&.bottomMd {
			margin-bottom: $gap-md;
		}
		&.bottomLg {
			margin-bottom: $gap-lg;
		}

		&.leftDefault {
			margin-left: $gap;
		}
		&.leftSm {
			margin-left: $gap-sm;
		}
		&.leftMd {
			margin-left: $gap-md;
		}
		&.leftLg {
			margin-left: $gap-lg;
		}

		/** All sides at once */
		&.allDefault {
			margin: $gap;
		}
		&.allSm {
			margin: $gap-sm;
		}
		&.allMd {
			margin: $gap-md;
		}
		&.allLg {
			margin: $gap-lg;
		}
	}

	&:not(.outside) {
		/**
			@example
			.topXs { padding-top: $gap-xs; }
			.topSm { padding-top: $gap-sm; }
			.topMd { padding-top: $gap-md; }
			.topLg { padding-top: $gap-lg; }

			...
		 */

		&.topDefault {
			padding-top: $gap;
		}
		&.topSm {
			padding-top: $gap-sm;
		}
		&.topMd {
			padding-top: $gap-md;
		}
		&.topLg {
			padding-top: $gap-lg;
		}

		&.rightDefault {
			padding-right: $gap;
		}
		&.rightSm {
			padding-right: $gap-sm;
		}
		&.rightMd {
			padding-right: $gap-md;
		}
		&.rightLg {
			padding-right: $gap-lg;
		}

		&.bottomDefault {
			padding-bottom: $gap;
		}
		&.bottomSm {
			padding-bottom: $gap-sm;
		}
		&.bottomMd {
			padding-bottom: $gap-md;
		}
		&.bottomLg {
			padding-bottom: $gap-lg;
		}

		&.leftDefault {
			padding-left: $gap;
		}
		&.leftSm {
			padding-left: $gap-sm;
		}
		&.leftMd {
			padding-left: $gap-md;
		}
		&.leftLg {
			padding-left: $gap-lg;
		}

		/** All sides at once */
		&.allDefault {
			padding: $gap;
		}
		&.allSm {
			padding: $gap-sm;
		}
		&.allMd {
			padding: $gap-md;
		}
		&.allLg {
			padding: $gap-lg;
		}
	}

	&.isBlock {
		display: block;
	}
}

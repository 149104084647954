@import "variables";
.reactTelInput {
	width: 100%;
	input[type='tel'] {
		position: relative;
		display: inline-block;
		width: 100%;
		height: 32px;
		font-size: 12px;
		line-height: 1.5;
		color: #000;
		background-color: $white;
		background-image: none;
		border: 1px solid #d9d9d9;
		transition: all 0.3s;
		box-shadow: none;
		&:hover {
			border-color: #64a6e4;
		}
	}

	:global {
		.selected-flag {
			border: none;
			height: 100%;
			cursor: pointer;
		}
		.country-list .country {
			display: flex;
			align-items: center;
		}
	}
}

:global {
	.has-error :local(.reactTelInput) {
		input[type='tel'] {
			border-color: $red-1;
			&:focus {
				outline: 0;
				box-shadow: 0 0 0 2px rgba(217, 83, 79, 0.2);
			}
		}
	}
}

@import "variables";
.root {
	width: 600px;
	padding-left: 0;

	.labelCol {
		padding: $gap-sm $gap;
	}

	.validationError {
		color: $error-color;
	}
}
.detailsHeader {
	padding-bottom: $gap-sm;
	color: $title-color-1;
}

/**
	Overlay for the spinner
	-------------------
 */
@mixin overlay-not-allowed {
	position: relative;
	&:after {
		content: '';
		background-color: rgba(0, 0, 0, 0.1);
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		cursor: not-allowed;
	}
}

@import "variables";
// index component
.jobs {
	:global(.ant-tabs-nav-container) {
		padding-left: $gap;
	}
}

// JobInfo component
.jobInfoBody {
	border-top: $border-base;
	border-bottom: $border-base;
	background-color: $background-3;
}

// JobInfoHeader
.header {
	background-color: $background-1;
	h1,
	h2,
	h3 {
		margin: 0;
		font-size: 1rem;
		font-weight: 400;
		color: $white;
	}
}

.jobType {
	flex-basis: 20%;

	> span {
		padding-left: $gap;
		font-weight: 600;
	}
}

.job {
	.label {
		min-width: 110px;
		padding-right: $gap-sm;
	}
	.value {
		font-weight: 600;
		overflow-wrap: break-word;
	}
}

.disabledContainerWidth {
	min-width: 250px;

	.disabledLabel {
		min-width: fit-content !important;
	}

	.disabledValue {
		opacity: 0.5;
	}
}

// instructions

.content {
	margin: $gap 0;
	white-space: pre-line;
}

.pointer {
	cursor: pointer;
}

.pinToRight {
	margin-left: auto;
}

.actionButtonLine {
	@include flex-container;
	@include flex-align(center);
	@include flex-justify(flex-end);
	:global(.ant-select) {
		margin: 0 $gap;
	}
}

.master {
	overflow-wrap: break-word;
}

.statusLabel {
	margin-left: $gap;
	font-size: $font-size-base;
	line-height: 20px;
}

.instructionsCollapse {
	:global {
		.ant-collapse-item > .ant-collapse-header {
			padding-left: $gap-xxl;
		}
	}
}

.email {
	color: $primary-color;
	animation: forceIEToShowElem 0.01s;
}

.tag {
	font-weight: bold;
	background: $background-3;
	padding-left: $gap-sm;
	padding-right: $gap-sm;
}

.settingsButtonWrapper {
	@include flex-container(column);
	@include flex-align(center);
	@include flex-justify(space-around);

	& > * {
		margin: 2px 0;
	}

	:global(.ant-btn) {
		min-height: unset;
	}
}

.linkList {
	padding-top: $gap-md;

	li {
		display: inline-block;
		margin-right: $gap-md;
		margin-bottom: $gap-md;
	}

	span {
		text-decoration: underline;
	}
}

.defaultMessagingGroupWrapper {
	flex-basis: 40%;
}

.jobDetailsGrid {
	padding-bottom: $gap;
}

.tooltipContent {
	margin-bottom: 0;

	a {
		color: $white !important;
		line-height: 2;
	}
}

.linkedJob {
	flex-basis: 18%;

	> a {
		margin-left: $gap-sm;
		font-weight: 600;
		line-height: 1.2;
		font-size: $font-size-xs;
		padding: 2px 5px;
		color: $white !important;
		background-color: $blue-7;
	}

	> span {
		margin-left: $gap-sm;
		font-weight: 600;
	}

	i {
		margin-top: -$gap-xs;
	}
}

.multipleTooltipLinks {
	> div {
		width: $gap-xl * 14 !important;
	}
}

.disabledLink {
	span {
		opacity: 0.6;
	}
}

.updateSavings {
	margin-bottom: $gap-sm;
}

@keyframes forceIEToShowElem {
	to {
		color: $primary-color;
	}
}

.horizontalRow {
	margin: $gap -20px;
}

.tooltipContainer {
	max-width: 500px;
}

.jobCodeTab {
	.operationDisableIcon {
		:global(.anticon) {
			margin-right: 0;
			margin-left: $timeline-border-width;
		}
	}
}

@import "variables";
.root {
	&.addonAfter:first-child {
		border-top-right-radius: $input-border-radius;
		border-bottom-right-radius: $input-border-radius;
	}
}

.group {
	form & {
		:global {
			.ant-select {
				width: 100%;
			}
		}
	}
}

@import "variables";
.groupLine {
	line-height: 1;
	width: 100%;
}

.editButton {
	float: right;
}

.userIcon,
.groupIcon {
	display: flex;
	flex: 0 0 20px;
	justify-content: center;
}

.btnWrapper {
	display: flex;
	flex-direction: column;
	height: auto;

	.assignedToBtn {
		 height: $gap-md;
	}
}

.assignedToTitle {
	margin-bottom: 0;

	.title {
		margin-top: -$gap-md;
	}
}

@import "variables";
/**
	Aside
 */
.levelContainer {
	margin: $gap 0;
}

.levelTitle {
	display: inline-block;
	margin-bottom: $gap;
}

.levelTitle {
	margin-left: $checkbox-offset;
}

@import "variables";
.tag {
	border: 1px solid #445564;
	background-color: $white;
	border-radius: 2px;
	font-size: $font-size-xs;
	line-height: 1;
	padding: 4px 5px;
}

.tagWrapper {
	position: relative;
}

.dollarIn {
	position: absolute;
	left: -$gap-lg;
	font-size: 25px;
	top: -$gap-sm;
}

.dollarOut {
	position: absolute;
	left: -$gap-lg;
	font-size: 25px;
	top: -1px;
}

.dollarInOut {
	position: absolute;
	left: -$gap-xxl;
	font-size: 32px;
	top: -5px;
}

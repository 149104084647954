@import "variables";
.datePickerExtended {
	min-height: $input-height;
	position: relative;
	display: block; // Make it go full width
	:global {
		.ant-calendar-picker {
			width: 100% !important;
			min-width: 100% !important;
			.anticon {
				z-index: -1;
			}
		}
		.ant-input-affix-wrapper {
			position: absolute;

			/**
			* Prioritize input position in order to its icons (suffix) to be shown
			*/
			z-index: 1;
			.ant-input:not(:last-child) {
				padding-right: 40px;
			}
		}

		.anticon-calendar {
			cursor: pointer;
		}

		.ant-form-item-children {
			position: static;
		}

		.ant-input-suffix > i {
			margin-left: $gap-sm;
		}
	}

	:global(.ant-form-item) & {
		margin-bottom: 0;
	}
}

.datePickerExtendedPopup {
	:global {
		.ant-calendar-time {
			.ant-calendar-footer {
				.ant-calendar-today-btn {
					margin-top: $gap;
				}
			}
		}

		/**
		 * No selectors are applied to understand which mode is currently active (time/date)
		 * When selecting time, we don't want to see hover effects of labels because they aren't clickable.
		 * In date mode, `.ant-calendar-my-select` has the 3d position,
		 * In time mode, `.ant-calendar-my-select` has the 1st position
		 */
		.ant-calendar-header > div > .ant-calendar-my-select:nth-child(1) > a {
			cursor: default;
			pointer-events: none; /** prevent showing tooltip from `title` attribute */
			&:hover {
				color: inherit;
			}
		}
	}
}

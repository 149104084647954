@import "variables";
.subHeader {
	height: 30px;
	border-radius: 0;
	border: none;
	font-size: $font-size-sm;
	font-weight: 600;
	color: $table-header-color;
}

.row {
	margin-right: $gap !important;
	margin-top: -1px;
}

.rowHeader {
	margin-right: $gap !important;
	margin-bottom: $gap;
}

.col {
	padding-right: 0 !important;
	position: relative;
}

.box {
	display: inline-block;
	width: 95%;
	height: 100%;
	border: $border-base;
	padding: 0 $gap;

	.insideBox {
		width: 100%;
		word-wrap: break-word;
	}
}

.container {
	.row:last-child {
		margin-bottom: $gap-lg;
	}
}

.buttonCol {
	margin-top: $gap-xl * 0.5;
}

.icon {
	margin-left: $gap;
	position: absolute;
	top: $gap-lg;
	right: $gap-sm;
}

.iconButton {
	position: absolute;
	right: 0;
	top: 0;
}

.bgColor {
	background-color: $red-2;
}

.popover {
	width: 630px;
	:global {
		.ant-popover-inner-content {
			padding: 0;
			padding-bottom: $gap;
		}
		.ant-popover-title {
			font-weight: bold;
			padding: $gap-md $gap-lg;
		}
	}
	.popoverFooter > div {
		width: 100%;
		margin-right: $gap;
	}
}

.cargoList {
	margin: $gap;
	cursor: pointer;
	.box {
		width: 100% !important;
		&:hover {
			box-shadow: 0 0 3px 1px $cyan-1;
		}
	}
}

.cargoContainer {
	overflow-y: auto;
	max-height: 200px;
}

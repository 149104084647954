@import "variables";
.portCallLabel {
	font-size: 12px;
	line-height: 1em;
}

.root {
	height: 100%;
	display: flex;
	align-items: center;
}

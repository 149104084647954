@import "variables";
.sidebar {
	&:global {
		&.ant-tabs-left {
			> .ant-tabs-bar {
				width: 80px;
				@include sidebar;

				.ant-tabs-tab {
					@include sidebar-item;
					font-size: $font-size-xs;
					text-align: center;
					height: 70px;
					line-height: 1rem;
					padding: $gap $gap-sm $gap-sm;
					transition: transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1),
						color 0.3s cubic-bezier(0.645, 0.045, 0.355, 1),
						background-color 0.3s cubic-bezier(0.645, 0.045, 0.355, 1),
						-webkit-transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
					border-bottom: 1px solid $black;

					&-active {
						@include sidebar-item-active;
						color: $sidebar-bg;
					}

					&-disabled {
						&,
						.anticon {
							color: $sidebar-disabled-color;
						}
					}
				}
			}

			> .ant-tabs-content {
				padding: 0;
			}
		}
	}
}

.tabItem {
	@include flex-justify-align(flex, center, center);
	height: 100%;
	color: inherit;
	font-size: inherit;
	line-height: inherit;
	sup {
		right: $gap-lg !important;
		top: $gap-sm;
	}
}

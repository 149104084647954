@import "variables";
.header {
	min-height: 31px;
}

.iconWrapper {
	width: 30px;
	text-align: center;
	text-transform: none;
}

.tooltipContent {
	:global(.ant-tooltip-inner) {
		max-width: 400px;
		white-space: pre-line;
	}
}

.supplementalSection {
	background-color: $blue-2;
	padding: $gap;
	margin: 0 $gap $gap;
}

.supplemetalInfo {
	color: $gray-2;
}

.daCollapse {
	background-color: $blue-6;
	margin-bottom: $gap;
	&:global(.ant-collapse-borderless > .ant-collapse-item) {
		border-bottom: 1px solid $blue-6;
	}

	&:global(.ant-collapse-borderless
			> .ant-collapse-item
			> .ant-collapse-header) {
		padding-right: $gap;
	}
}
.tooltip {
	text-transform: initial;
}

.popover {
	text-transform: initial;
	width: 420px;
}

.inputField {
	margin-block: $gap-sm;
	width: 100px;
}

.popoverContent {
	margin-block: $gap;
}

.modalContent {
	padding: $gap-sm 0;
	margin-left: $gap-sm;
}

.modalContainer {
	max-width: 500px;
}

@import "variables";
.titleTable {
	color: $gray-2;
	height: 30px;
	border-bottom: 2px solid $blue-4;
	:global(.table-col) {
		width: 20%;
		&:first-child {
			padding-left: $gap-lg;
		}
	}
}

.portCallTable {
	position: relative;
}

.tableHeader {
	td {
		padding: $gap-md 0;
		&:first-child {
			padding-left: $gap;
		}
	}
	.bold {
		font-weight: $font-weight-semi-bold;
	}
	:global(.table-col) {
		width: 20%;
	}
}

.portCallRow:not(:first-child) {
	border-top: 3px solid $blue-6;
}

.portJobsTable {
	:global(.ant-table-tbody > tr > td),
	:global(.ant-table-thead > tr > th) {
		padding: 0 $gap-md 0 0;
		background: transparent;
		border: 0;
		word-break: break-word;
	}
	:global(.ant-table-thead > tr > th) {
		color: $gray-2;
		font-weight: $font-weight-semi-bold;
		text-align: left;
		&:first-child {
			padding-left: $gap;
		}
	}
	:global(.ant-table-tbody > tr > td:first-child) {
		padding-left: $gap;
	}
	.portJobLink {
		font-size: $font-size-base;
		font-weight: $font-weight-semi-bold;
		line-height: $input-height;
	}
}

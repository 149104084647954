@import "variables";
.root {
	&:global {
		&.ant-form-vertical .ant-form-item-label {
			display: inline-block;
		}
	}
}

.formItemWrapper.withAsterisk {
	position: relative;
}

.formItemAsterisk {
	position: absolute;
	top: -5px;
	right: -13px;
}

.item {
	margin-bottom: $form-item-margin-bottom;

	> :global(.ant-form-item-label) {
		text-align: left;
		line-height: 1.3;
		> label {
			color: $text-color-base;
			white-space: normal;
			&:after {
				display: none;
			}

			&:global(.ant-form-item-required) {
				&:before {
					content: '';
				}
				&:after {
					margin-left: 3px;
					display: inline-block;
					margin-right: 4px;
					color: $error-color;
					font-size: 10px;
					line-height: 1;
					content: '*';
				}
			}
		}
	}

	:global {
		.ant-form-explain {
			font-size: $font-size-sm;
			margin-top: 4px;
			margin-left: 8px;
		}

		.ant-form-item-control {
			line-height: normal;
		}

		.ant-input-suffix > i {
			color: $primary-color;
		}

		.ant-input-group-addon {
			background-color: transparent;
			border-color: transparent;
		}

		.has-error .ant-select-selection {
			border-color: $error-color !important;
			&:focus,
			&:active {
				box-shadow: 0 0 0 2px rgba($error-color, 0.2) !important;
			}
		}
	}
}

.noMarginBottom {
	margin-bottom: 0;
}

.input {
	@extend %input;

	&:global {
		&-disabled {
			@extend %input-disabled;
		}
	}
}

.noFormItemMarginBottom {
	:global {
		.ant-form-item {
			margin-bottom: 0;
		}
	}
}

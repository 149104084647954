@import "variables";
.jobLabel {
	color: $white;
	font-weight: $font-weight-semi-bold;
	line-height: 1.2;
	font-size: $font-size-xs;
	padding: 2px $gap-sm;

	&:hover {
		opacity: 1; /* Prevent native transparency */
	}

	&:global(.ant-tag) {
		border-radius: 2px;
	}
}

.jobCode1 {
	background-color: nth($job-codes-list, 1);
}
.jobCode2 {
	background-color: nth($job-codes-list, 2);
}
.jobCode3 {
	background-color: nth($job-codes-list, 3);
}
.jobCode4 {
	background-color: nth($job-codes-list, 4);
}
.jobCode5 {
	background-color: nth($job-codes-list, 5);
}
.jobCode6 {
	background-color: nth($job-codes-list, 6);
}

.noColorTag {
	font-weight: $font-weight-bold;
	line-height: 16px;
	&.notSelected {
		color: $blue-7;
	}
}

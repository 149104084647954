@import "variables";
.acceptJobModal {
	:global(.ant-modal-body) {
		padding: 0 $gap-lg;
	}

	:global(.ant-modal-header) {
		border-bottom: 0;
	}

	.footerCheckbox {
		margin-left: auto;
		padding-right: $gap;
	}

	.SLAInfoBoxInner {
		margin: 0.5rem 0 1rem;
	}

	.SLAInfoBox {
		display: flex;
		padding: $gap-sm $gap;
		text-decoration: none;
		border: $border-base;
		border-radius: 5px;

		.SLAInfoBoxTitle {
			font-weight: 600;
		}

		.SLAInfoBoxSubtitle {
			font-size: 0.85rem;
		}
	}

	.scrollableText {
		overflow-y: auto;
		width: 100%;

		p {
			margin: $gap-sm 0;
		}
	}

	.footerWrapper {
		padding-top: $gap;
		width: 100%;
	}

	h4 {
		font-weight: bolder;
	}
}

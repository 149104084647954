@import "variables";
.row {
	text-align: center;
	padding: 5px;
	color: $text-color-base;

	> p {
		margin-bottom: 0;
	}
}

.hidden {
	visibility: hidden;
}

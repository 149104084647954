@import "variables";
.root {
	@include flex-container(column);

	/**
		Allow elements to shrink below their minimum content size
	 */
	> * {
		min-width: 0;
	}

	*:not(.root) > & {
		height: 100%;
	}

	> .root {
		@extend .sticky;
	}

	/**
		Override overflow: hidden for tab pane to make it scrollable in case ScrollableLayout.
	 */
	:global(.ant-tabs) {
		overflow: auto;
	}
}

.horizontal {
	> .root {
		@include flex-container;
	}
}

.sticky {
	/**
		TODO
		LazyLoad starts triggering onEnter infinitely if flex-basis is set to `auto`
	 */
	flex: 1;
	overflow-y: auto;
}

.stretch {
	@include make-gutter;
}

.asColumn {
	@include make-gutter(column);
}

@import "variables";
.root {
	background: $gradient;
	overflow-y: auto;

	img {
		margin-bottom: 40px;
	}
}

.logo {
	width: auto;
	margin-top: 0;
}

@import "variables";
.tags {
	margin-top: 8px;
	:global {
		.ant-select-disabled li {
			cursor: not-allowed;
		}
	}
}

.statusBadgeDot {
	:global {
		.ant-badge-status-dot {
			width: 8px;
			height: 8px;
		}
	}
}

.selectSpan {
	:global {
		.ant-select-selection {
			.ant-select-selection__rendered {
				.ant-select-selection__placeholder {
					margin-left: 0;
				}
			}
		}
	}
}

@import "variables";
.header {
	position: fixed;
	width: 100%;
	padding: 0;
	z-index: 99;
	height: $navbar-height;
	line-height: normal;
	background: $gradient;
}
.headerContent {
	height: inherit;
}
.row {
	@include flex-justify(space-between);
	margin-left: 0;
	margin-right: 0;
	height: 100%;
}
.breadcrumbs {
	margin: 0 $gap-md;

	:global {
		.ant-breadcrumb-link {
			color: $border-color;
			a,
			// override default color for the :last-child
			a:last-child {
				color: $white;
			}
			> span {
				&:not(:first-child) {
					padding-left: $gap;
				}
			}
			.anticon {
				margin-right: $gap-sm;
				color: $primary-color;
				font-size: $icon-size-md;
			}
		}
		.ant-breadcrumb-separator {
			margin: 0 0 0 $gap-sm;
		}
		// separator
		.anticon-right {
			font-size: $icon-size-xs;
		}
	}
}

.toggleButton {
	padding-left: $gap !important;
	padding-right: $gap !important;

	> i {
		font-size: 14px;
	}

	&,
	&:hover,
	&:focus,
	&:active {
		color: $white !important;
	}
}

@import "variables";
/**
	Aside
 */
.asideOverlay {
	@extend %overlay-not-allowed;
}

.levelContainer {
	margin: $gap 0;
}

.asideTitle,
.levelTitle {
	display: inline-block;
	margin-bottom: $gap;
}

.levelTitle {
	margin-left: $checkbox-offset;
}

.pagesSelect {
	width: 55px;
}

@import "variables";
.root {
	&:global {
		> .ant-alert {
			margin: $gap 0;
		}
		> .ant-alert .ant-btn.ant-btn-loading:before {
			background: transparent;
		}
	}
}

.overdue {
	color: $error-color;
}

.link {
	padding: 6px 0;
}

.button {
	margin-right: $gap-sm;
}

@import "variables";
.loadingOverlay {
	background: #fff;
	z-index: $z-index-ten;
}

.swapIcon {
	position: absolute;
	top: 50%;
	right: -9px;
	transform: translateY(-50%);
	cursor: pointer;
}

.swapContainer {
	position: relative;
	:global {
		.ant-tooltip-inner {
			width: $gap * 30;
		}
	}
}

.disableSwapIcon {
	opacity: 0.5;
}

.checkBoxParentColumn {
	:global {
		.ant-form-item {
			margin-bottom: -17px;
		}
	}
}

.checkBox {
	float: right;
	z-index: 99;
	:global {
		.ant-tooltip-inner {
			min-width: max-content;
		}
	}
}

.nonIssHubCheckBox {
	display: none;
}

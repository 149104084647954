@import "variables";
.wrapper {
	:global {
		.ant-collapse > .ant-collapse-item {
			border: none;
		}
	}
}

.topBorder,
.belowServiceBorder {
	width: calc(100% + 20px);
	position: relative;
	left: -10px;
}

.topBorder {
	margin-top: $gap-lg;
	border-bottom-width: 2px;
}

.serviceName {
	position: relative;
	bottom: 1px;
}

.goToJobBtn {
	width: 100%;
}

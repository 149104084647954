@import "variables";
.row {
	cursor: pointer;
	border-bottom: $border-base;
	min-height: 80px;

	@include flex-box;
	transition: all 0.1s ease-in-out;

	&:hover {
		position: relative;
		box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.24);
	}
}

.unread {
	background-color: $green-4;
	:global {
		.table-col-read {
			&::before {
				content: '';
				display: inline-block;
				width: 7px;
				height: 7px;
				border-radius: 100%;
				background-color: $table-row-undread-dot-bg;
				vertical-align: middle;
				margin: $gap-sm;
			}
		}
	}
}

.tableWrap {
	table-layout: fixed;
}

.table {
	border-top: $border-base;

	:global {
		.table-col {
			padding: 12px 8px;
			flex: 1;
			overflow: hidden;
			word-break: break-word;
			@include flex-container(column);

			.anticon {
				min-width: 20px;
				text-align: center;
				font-size: $font-size;
			}

			.col-row {
				&:not(:last-child),
				+ .col-row:not(:last-child) {
					margin-bottom: 5px;
				}

				&-unassigned {
					font-style: italic;
				}
			}

			.col-accent {
				font-weight: 600;
			}

			&-read {
				flex: 0 1 25px;
			}

			&-status {
				flex: 0 1 10%;

				ul {
					margin-top: 5px;
				}

				.anticon-warning {
					margin-top: 0;
				}
			}

			&-subject {
				$subject-padd-left: $gap-xxl;

				font-weight: normal;
				padding-left: $subject-padd-left;
				position: relative;

				.subject {
					font-weight: 600;
				}

				.anticon {
					position: absolute;
					left: 13px;
					min-width: 0;
					margin-top: 0;

					&-warning {
						left: 0;
						& + .anticon-paper-clip {
							color: $warning-color;
						}
					}

					&-paper-clip {
						left: $subject-padd-left * 0.5;
					}
				}
			}

			&-company {
				.col-row-unassigned {
					font-style: normal;
				}
			}
		}

		@media (min-width: 768px) {
			.table-col-sm-2 {
				flex-grow: 2;
			}
		}
	}
}

.tooltipContent {
	:global {
		.ant-tooltip-inner {
			padding: 0;
			background-color: $white;
		}
		.ant-tooltip-arrow {
			border-left-color: $white;
		}
	}
}

.tooltipRow {
	height: 30px;
	padding: 5px 20px;
	color: $text-color-base;
	&:not(:last-child) {
		border-bottom: $border-base;
	}
}

.noPadding {
	:global {
		.table-col {
			padding: 0;
		}
	}
}

.splitViewWrapper {
	height: 80%;
	padding-top: 1px;
	.threadList {
		padding-right: $gap-xl;
	}
	.threadListScroll {
		border-right: 1px solid $blue-4;
	}
}

.continueButton {
	&:global.ant-btn {
		padding-right: $gap;
	}
}

@import "variables";
.root {
	line-height: 1.3;

	> span {
		display: inline-block;
		font-weight: 600;
	}
}

.vertical {
	padding: 0 $gap;
}

.horizontal {
	> span {
		padding-left: 5px;
	}
}

@import "variables";
@mixin alert-variant($background, $border) {
	background-color: $background;
	border: 1px solid $border;

	> .anticon:first-child {
		color: $border;
	}
}

%message {
	font-weight: $font-weight-bold;
	color: $alert-message-color;
}

%description {
	font-weight: $font-weight-semi-bold;
	color: $alert-description-color;
}

.root {
	+ .root {
		margin-top: $gap;
	}

	:global {
		strong {
			@extend %message;
		}

		p {
			font-size: $font-size;
			margin-bottom: 0;
			@extend %description;
		}
	}

	&:global {
		/**
			Common
		 */
		> .ant-alert-message {
			@extend %message;
		}

		> .ant-alert-description {
			@extend %description;
		}

		> .ant-alert-icon,
		> .ant-alert-close-icon {
			top: 50%;
			transform: translateY(-50%);
		}

		> .ant-alert-icon {
			font-size: 18px;
			min-width: 18px;
			left: $gap-sm;
		}

		/**
			Toast notification
		 */
		&.ant-alert-no-icon {
			padding: 7px 14px;

			> .ant-alert-message {
				font-size: $font-size;
				padding-right: $gap-sm;
			}

			> .ant-alert-message,
			> .ant-alert-description {
				display: inline-block;
			}

			> .ant-alert-close-icon {
				right: 14px;
				font-size: 9px;
				line-height: normal;
			}
		}

		/**
			Hints with Icon
		 */

		/* Content defined in `message` property */
		&:not(.ant-alert-no-icon) {
			padding: 7px ($gap-lg * 2) 8px 55px;
			> .ant-alert-message {
				font-size: 14px;
			}

			> .ant-alert-icon {
				left: 18px;
			}
		}

		/* Content defined both in `message`/`description` properties */
		&.ant-alert-with-description:not(.ant-alert-no-icon) {
			> .ant-alert-message {
				margin-bottom: 0;
			}

			> .ant-alert-description {
				line-height: normal;
			}
		}

		/**
			Types
		 */
		&.ant-alert-info {
			@include alert-variant($info-bg, $info-color);
		}

		&.ant-alert-error {
			@include alert-variant($error-bg, $error-color);
		}

		&.ant-alert-success {
			@include alert-variant($success-bg, $success-color);
		}

		&.ant-alert-warning {
			@include alert-variant($warning-bg, $warning-color);
		}
	}

	&.transparent {
		background: none;
		border: none;
		display: flex;
		padding: 0;

		:global {
			.ant-alert-icon {
				position: relative;
				top: 2px;
				left: 0;
				margin-right: $gap-md;
				transform: none;
			}
		}
	}
}

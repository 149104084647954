@import "variables";
.root {
	width: 100%;
	overflow: hidden; /** Prevent collapsing of margin */
	img {
		display: block;
		margin: 50px auto;
	}
}

.xxs {
	img {
		width: $icon-size-sm;
		height: $icon-size-sm;
	}
}

.xs {
	img {
		width: 16px;
		height: 16px;
		margin: 0 auto;
	}
}

.sm {
	img {
		width: 24px;
		height: 24px;
	}
}

.md {
	img {
		width: 60px;
		height: 60px;
	}
}

.lg {
	img {
		width: 64px;
		height: 64px;
	}
}

.visible {
	@extend %flex-container;
}

.hidden {
	display: none;
}

.inline {
	display: inline-block;
	width: auto;
	img {
		margin: 0 auto;
	}
}

/** Make sure they override the rest */
.root.centerCenter {
	height: 100%;
	@extend %align-center;
}

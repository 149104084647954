@import "variables";
.root {
	height: 50px;
	border-bottom: $border-base;

	h3 {
		font-weight: 600;
		line-height: $page-header-height;
		margin-bottom: 0;
		@include truncate-line(true);
	}
}

@import "variables";
.wrapper {
	height: 100%;
	position: relative;

	*[class*='fluid'] > & > .viewer {
		&:after,
		&:before {
			@include make-gutter;
		}
	}
}

.viewer {
	&:after,
	&:before {
		content: '';
		position: absolute;
		background-color: $success-color;
		z-index: 100;
	}

	&.horizontal {
		&:after,
		&:before {
			top: 0;
			bottom: 0;
			width: 5px;
		}
		&:after {
			left: 0;
		}
		&:before {
			right: 0;
		}
	}

	&.vertical {
		&:after,
		&:before {
			left: 0;
			right: 0;
			height: 5px;
		}

		&:after {
			top: 0;
		}
		&:before {
			bottom: 0;
		}
	}
}

.tag {
	width: 100%;
}

@import "variables";
.root {
	&:global {
		&.ant-tree li {
			span.ant-tree-switcher.ant-tree-switcher {
				&_open,
				&_close {
					&:after {
						font-size: $icon-size-xs;
					}
				}
			}
		}
	}

	:global {
		.ant-tree-checkbox {
			> .ant-tree-checkbox-inner {
				@extend %checkbox-inner;
			}
		}
	}
}

@import "variables";
.root {
	font-size: $font-size-base;
	&:after {
		font-size: inherit;
		display: inline-block;
		vertical-align: bottom;
		-webkit-animation: ellipsis steps(4, end) 1s infinite;
		animation: ellipsis steps(4, end) 1s infinite;
		content: '';
	}
}

@keyframes ellipsis {
	0% {
		content: '';
	}
	25% {
		content: '.';
	}
	50% {
		content: '..';
	}
	75% {
		content: '...';
	}
}

@import "variables";
.container {
	.iconButton {
		color: $gray-9;
		margin-left: $gap !important;
		padding: 0;
		vertical-align: top;

		&:hover {
			color: $gray-10;
		}

		&:disabled,
		&[disabled] {
			color: $gray-9;
		}

		i {
			margin-right: 0 !important;
			padding-top: 1px;
		}
	}
}

.messageHeader {
	margin: -$gap 0;
	padding: $gap-md $gap-md 0;
	background-color: $header-bg;
	.label {
		display: block;
		text-transform: uppercase;
		font-size: $font-size-xs;
		color: $title-color-1;
		font-weight: bold;
		line-height: 30px;

		@media only screen and (min-width: 48em) {
			text-align: right;
		}
	}
}

.form {
	:global {
		.ant-row {
			margin-bottom: 10px;
		}

		.Select {
			line-height: initial;
		}
	}
}

.tabPane {
	:global {
		.ant-tabs-tab-disabled {
			color: $gray-5 !important;

			&:hover,
			&:focus {
				color: $gray-5 !important;
			}
		}

		.ant-tabs-content {
			overflow-x: hidden;
		}
	}
}

.content {
	height: 52vh;
	overflow-y: scroll;
}

@import "variables";
@import '../../../styles/utils/mixins';

.gutter[class*='flexboxgrid__col'] {
	padding-left: 0;
	padding-right: 0;
}

.end {
	@include flex-container;
	@include flex-justify(flex-end);
}

.middle {
	@include flex-container;
	@include flex-align(center);
}

.bottom {
	@include flex-container;
	@include flex-align(flex-end);
}

@import "variables";
.formItem {
	margin: $gap-sm 0;
	:global {
		.ant-form-item {
			margin-bottom: 0 !important;
		}
	}
}

.serviceSectionDropDownSelect {
	max-height: 130px;
	:global(.ant-select-dropdown-menu) {
		max-height: 130px;
	}
}

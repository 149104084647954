@import "variables";
.collapse {
	width: 100%;
}

.coverSheet {
	padding: $gap-sm 0;

	&.coverSheetDisabled {
		background-color: $background-2;
	}

	:global {
		.ant-form-item {
			margin-bottom: 0;
			padding-top: $gap-sm;
		}
	}
}

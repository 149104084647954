@import "variables";
.root {
	:global {
		.ant-table-header {
			margin-bottom: 4px;
			background-color: transparent;
		}
		.ant-table {
			overflow: visible;
			tr > td {
				border-bottom: 0;
				padding: $gap-xs $gap;
				&:first-child {
					padding-left: 30px;
				}
			}
			tr:only-child > td {
				padding-top: 5px;
				padding-bottom: 5px;
			}
			table {
				table-layout: fixed;
			}
		}
		.ant-table-thead {
			> tr {
				> th {
					height: 30px;
					border-radius: 0;
					border: none;
					font-size: $font-size-sm;
					font-weight: 600;
					color: $table-header-color;
					&:first-child {
						padding-left: 32px;
					}
				}
			}
		}
		.ant-table-tbody {
			> tr {
				> td {
					word-break: break-word;
					word-wrap: break-word;
					@media screen and (-ms-high-contrast: active),
						(-ms-high-contrast: none) {
						min-height: $gap-md * 3;
					}
				}
			}
		}
	}
}

.row {
	height: $table-row-height;
}

.center {
	/* Without !important it gets easy overwriten by specificity of Ant.Table */
	text-align: center !important;
}

.inactiveGroupMember {
	color: $red-1;
}

.assignToUser {
	margin-right: $gap;
}

.assignDaDropDownSelect {
	max-height: 200px;
	:global(.ant-select-dropdown-menu) {
		max-height: 200px;
	}
}

%flex-container {
	display: flex;
}

%flex-inline-box {
	@include flex-box(inline-flex);
}

%justify-center {
	@include flex-justify(center);
}

%align-center {
	@include flex-align(center);
}

%flex-container-column {
	@include flex-container(column);
}

%align-self-right {
	align-self: flex-end;
}

/**
	flex - <justify> - <align>
	<justify> - horizontal arrangement of the flex layout: start, end, center, space-around, space-between
	<align> - the vertical alignment of the flex layout: top, middle, bottom
 */

%flex-start-middle {
	@include flex-justify-align(flex, start);
}

%flex-center-middle {
	@include flex-justify-align(flex);
}

%flex-between-middle {
	@include flex-justify-align(flex, space-between, center);
}

%col-dont-grow {
	flex: none;
}

/**
 * Use it to add a consistent bottom shadow to an element
 */
%shadowed-bottom {
	@include style-bottom-shadowed();
}

%overlay-not-allowed {
	@include overlay-not-allowed;
}

%animated {
	animation-fill-mode: both;
	animation-duration: 1s;
}

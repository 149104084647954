@import "variables";
.root {
	:global {
		.ant-select-selection__choice {
			background-color: $background-3;
			border-radius: 2px;
			border: 1px solid $border-color-3;
			padding-left: 4px;
			display: flex;
		}
		.ant-select-selection--multiple .ant-select-selection__choice__remove {
			font-size: $icon-size-xs;
			color: $standard-color;
		}
	}
}

@import "variables";
$tag-height: 15px;
.root {
	:global {
		ul > li.ant-select-selection__choice {
			height: $tag-height;
			border: 0;
			padding: 0;
			margin-top: 6px;
			line-height: normal;

			&:hover {
				background-color: $white;
			}

			.ant-select-selection__choice__remove {
				color: $white;
				font-weight: $font-weight-medium;
				font-size: 7px;
				line-height: normal;
				transform: translateY(-50%);
				top: 50%;

				/**
					Block height isn't equal to content.
					Icon issue
				 */
				height: 8px;
			}

			.ant-tag {
				height: inherit;
				display: block;
				padding-right: 25px;
			}
		}

		.ant-select-selection__choice__content {
			height: inherit;
		}
	}
}

.root,
.rootDropdown {
	:global {
		.ant-tag {
			color: $white;
			font-size: $font-size-xs;
			font-weight: $font-weight-semi-bold;
			line-height: $tag-height;
		}
	}
}

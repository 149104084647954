@import "variables";
.threadHistory {
	position: relative;
	@include flex-container(column);
	flex: 1;
	overflow-y: auto;
	padding: 20px 15px;
}

.originatedFrom {
	width: 100%;
	height: $page-header-height;
	line-height: $page-header-height;
	background: $background-2;
	color: #9b9b9b;
	.originatedFromInner {
		width: 80%;
		margin: 0 auto;
		span {
			@include truncate-line(true);
		}
	}
}

.notAssignedToMe {
	padding-top: 70px;
}
div.assignedToMe {
	height: calc(100% - 2px);
}

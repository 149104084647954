@import "variables";
.timePopover {
	:global(.ant-popover-inner) {
		:global(.ant-popover-inner-content) {
			background: rgba($navbar-primary-bg, 0.8);
			border-radius: 2px;
			color: $white;
			span {
				font-weight: 600;
				font-size: 12px;
			}
		}
	}
	:global(.ant-popover-content .ant-popover-arrow) {
		top: -10px;
		border-width: 9px;
		&,
		&:after {
			border-bottom-color: $navbar-primary-bg;
			opacity: 0.8;
		}
	}
}

.infoIcon {
	margin-bottom: 1px;
	margin-left: 2px;
}

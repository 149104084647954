@import "variables";
.divider {
	width: calc(100% + #{$gap-lg});
	position: relative;
	left: -$gap;
}

.infoIcon {
	position: relative;
}

@import "variables";
.withFloatingButton {
	&:hover {
		.copyButton {
			opacity: 1;
		}
	}

	.copyButton {
		opacity: 0;
		transition: opacity 0.3s ease;
	}
}

.copyButton {
	display: inline-flex;
	margin-left: $gap-sm * 2;
	padding: 0 $gap-sm * 2;
	color: $gray-2;

	&:global {
		&[ant-click-animating-without-extra-node]::after {
			animation: 0s !important;
		}
	}
}

@import "variables";
.root {
	margin-bottom: $gap;

	> header,
	> footer {
		padding: $gap-sm $gap;
	}

	> header {
		border-bottom: inherit;
	}
}

.content {
	padding-bottom: $gap;
}

.stretch {
	@include make-gutter;
}

.primary {
	background-color: $background-3;
	border: 1px solid $border-color-1;
}

.standard {
	background-color: $background-3;
}

.bordered {
	border: 2px solid $blue-6;
}

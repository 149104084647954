@import "variables";
@import '../antd/Button/Mixins';

.root {
	@include flex-box(inline-flex);
}

.menu {
	box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
	border-color: $border-color;

	> li {
		&:hover {
			background: $background-5;
		}

		&[class*='-selected'] {
			font-weight: 600;
			background-color: $background-3;
			color: inherit;
		}
	}
}

.button {
	color: $white;
	font-size: $font-size-xs;
	@include ant-button-variant($black, $white, $black);

	&.success {
		@include ant-button-variant($white, $success-bg);
	}

	&.warning {
		@include ant-button-variant($white, $warning-bg);
	}

	&.primary {
		@include ant-button-variant($white, $primary-bg);
	}

	&.secondary {
		@include ant-button-variant($white, $standard-bg);
	}

	&:not(:global(.ant-btn-icon-only)) {
		min-height: 0;
		line-height: unset;
		padding: 0 $gap 0 $gap-sm;
		min-width: 70px;
		> i {
			font-size: 6px;

			height: 4px;
			position: absolute;
			right: 3px;
		}
	}
}

.dropdown {
	button {
		padding: 0 $gap-md 0 $gap-sm;
		height: 20px;
		line-height: 1.4;
		text-align: left;
		border-color: $gray-6 !important;

		&:hover,
		&:focus {
			border-color: unset !important;
		}
		> span {
			height: 100%;
		}

		/**
		* Position caret icon relative to the button
		*/
		&:active,
		&:focus {
			> span {
				position: static;
			}
		}

		i:global([class*='caret-']) {
			position: absolute;
			right: $gap-sm;
			top: 50%;
			font-size: $font-size-xs;
			margin-top: -$gap-sm;
			color: $default-color;
		}
	}
	.dropdownLabel {
		font-size: $font-size-xs;
		line-height: inherit;
	}
	.menu {
		padding-top: 0;
		> li {
			line-height: 24px;
			padding: 0 $gap-sm;
			font-size: $font-size-xs;
		}
	}
}

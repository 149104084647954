@import "variables";
.root {
	position: relative;
	margin-top: 0;
	margin-bottom: 0;
	border-bottom: 0;
	padding: $gap $grid-gutter;
	min-height: $page-header-height;
	flex-shrink: 0;

	h1 {
		font-size: 1rem;
	}
}

.justified {
	border: 0;
	padding-left: $gap;
	padding-right: $gap;
	@include flex-justify-align(flex, space-between);
}

.row {
	flex-wrap: nowrap;
}

.stretch {
	@include make-gutter;
}

.main {
	@extend .shadowed;
	background-color: $header-bg;
	h1 {
		font-size: 15px;
		margin-bottom: 0;
		font-weight: $font-weight-semi-bold;
	}
}

@mixin headings {
	h1,
	h2,
	h3,
	h4,
	h5 {
		@content;
	}
}

%slimHeader {
	.root {
		padding-top: 0;
		padding-bottom: 0;
		background-color: $main-bg;
		@extend .justified;

		@include headings {
			font-size: $font-size;
			font-weight: 400;
			color: $white;
		}

		:global {
			.anticon {
				color: rgba($white, 0.5);
				&-cross {
					font-size: $icon-size-xs;
				}
			}
		}
	}
}

.primary {
	@extend %slimHeader;
	.subtitle {
		height: $page-header-height;
		background-color: $blue-5;
		@include headings {
			color: $white;
		}
	}
}

.secondary {
	@extend %slimHeader;
	.root {
		@extend .shadowed;
	}
	.subtitle {
		height: 40px;
		background-color: $background-2;
		@include headings {
			font-weight: bold;
			color: $title-color-3;
		}
	}
}

.gullGray {
	@extend %slimHeader;
	.root {
		@extend .shadowed;
	}
	.subtitle {
		height: $page-header-height;
		background-color: $background-1;
		padding: $gap-sm $gap;
		text-transform: uppercase;
		@include headings {
			font-weight: bold;
			color: $white;
		}
	}
}

.subtitle {
	display: flex;
	align-items: center;
	padding-left: $gap;
	padding-right: $gap;
	@include headings {
		font-size: $font-size;
		text-transform: uppercase;
	}
}

.shadowed {
	z-index: 10; /* Need to be raised above their sibligns to show the bottom shadow */
	@extend %shadowed-bottom;
}

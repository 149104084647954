@import "variables";
.wrapper {
	text-align: center;
	div:first-child {
		margin-bottom: $gap-sm;
	}
}
.count {
	border-radius: 50%;
	border: none;
	color: $white;
	font-weight: bold;
	font-size: $font-size-md;
	width: 32px;
	height: 32px;
}
.positive {
	background-color: $cyan-1;
}

.attention {
	background-color: $warning-color;
}

.negative {
	background-color: $error-color;
}

.clickable {
	cursor: pointer;
}

@import "variables";
.root {
	.fluid {
		width: 100%;
		padding-left: $gap;
		padding-right: $gap;
	}
	.selectWidth {
		max-width: 200px;
	}
}

.count {
	vertical-align: inherit;
	min-width: 18px;
	&:global {
		> sup.ant-badge-count {
			background-color: $gray-3;
			color: $white;
			font-size: $font-size-xs;
		}
	}
}

.iconDisbaled {
	opacity: 0.3;
}

.iconWrapper {
	font-size: $gap-md;
	margin-left: -3px;
}

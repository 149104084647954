@import "variables";
.relative {
	position: relative;
}

.fit {
	height: 100%;
	width: 100%;
}

.absolute {
	position: absolute;

	&.topRight {
		right: 0;
	}

	&.topLeft {
		left: 0;
	}

	&.center {
		@extend %transform-center-center;
		z-index: 1;
	}
}

@import "variables";
.radioGroup {
	width: 100%;
}

.userSelectCol {
	:global {
		.ant-form-item {
			margin-bottom: 0;
		}
	}
}

@import "variables";
.root {
	cursor: inherit;
	height: auto;
	margin-right: 0;

	:global(.anticon) {
		vertical-align: middle;
	}
}

.xs {
	font-size: $font-size-xs;
	line-height: 1.6;
}

.sm {
	font-size: $font-size;
}

.color {
	color: $white;
}

.closable {
	:global(.anticon-close) {
		margin-left: $gap-sm;
	}
}

@mixin customize-tag(
	$background,
	$color: darken($background, 40%),
	$border: $color
) {
	:global {
		border-color: $border;
		background-color: $background;
		color: $color;
		.ant-tag-text {
			color: $color;
		}
		.anticon {
			color: $color;
		}
	}
	&.borderless {
		border-color: $background;
	}
}

.success {
	@include customize-tag($success-bg, $success-color);
}

.info {
	@include customize-tag($info-bg, $info-color);
}

.warning {
	@include customize-tag($warning-bg, $warning-color);
}

.error {
	@include customize-tag($error-bg, $error-color, $error-bg);
}

.link {
	@include customize-tag($background-3, $link-color);
}

.dark {
	@include customize-tag($standard-color, $black);
}

.gray {
	@include customize-tag($tag-type-gray-background, $black);
}

.default {
	@include customize-tag($white, $black, $tag-default-border);
}

.ghost {
	background-color: transparent;
}

.clickable {
	cursor: pointer;
}

.bold {
	font-weight: 600;
}

.borderless {
	border: none;
}

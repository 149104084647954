@import "variables";
$height: 50px;

.overlay {
	z-index: $z-index-overlay-default !important;
}

.menu {
	&:global {
		&.ant-menu-inline {
			border-right: 0;
			user-select: none;
			.ant-menu-item,
			.ant-menu-submenu-title {
				height: $height;
				line-height: $height;
				@include sidebar-item;
				font-size: $font-size-sm;
				border-bottom: 1px solid $black-2;
				width: 100%;

				&:not(.ant-menu-item-selected):hover {
					background-color: $blue-8;
				}

				> a {
					display: inline-block;
					font-size: $font-size-sm;
					&,
					&:hover {
						color: inherit;
					}
				}
			}
		}
	}
	:global {
		.ant-menu-item,
		.ant-menu-submenu {
			&.ant-menu-item-selected {
				@include sidebar-item-active;
				color: $gray-1;
				&:after {
					border-right-width: 4px;
					border-right-color: $tabs-inc-bar-bg;
					right: initial !important;
					left: 0 !important;
				}
			}
		}
	}
}
.releaseVersion {
	position: absolute;
	bottom: 35px;
	color: #5dbcd2;
	background-color: #033354;
	padding-left: 1vw;
	margin: 0;
}
.releaseDate {
	position: absolute;
	bottom: 15px;
	color: #5dbcd2;
	background-color: #033354;
	padding-left: 1vw;
	margin: 0;
}
.sidebar {
	@include sidebar;
	height: 100%;
	width: 220px;
	z-index: $z-index-overlay-default + 1 !important;
}

.sidebarContent {
	overflow-y: auto !important;
}

.menuItemTag {
	position: absolute;
	font-size: 8px;
	line-height: 1.6;
	right: 3px;
	top: 3px;
}

@import "variables";
.charterer {
	&:not(:last-child) {
		border-bottom: 1px solid $border-color-2;
	}
	h4 {
		font-weight: bold;
		text-transform: uppercase;
	}
}

.instruction {
	margin: $gap 0;
}

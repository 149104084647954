@import "variables";
.backButton {
	display: flex;
	align-items: center;
}

.cancelButton {
	&:global.ant-btn {
		padding: 0 $gap;
	}
}

@import "variables";
.wrapper {
	margin-top: $gap-lg;
	:global {
		.ant-form-item {
			margin-bottom: 0;
		}
	}
}

.companyName {
	text-transform: uppercase;
	font-size: 1rem;
	font-weight: bold;
}

.checkboxRow {
	margin-top: 23px;
}
.marginLeft {
	margin-left: 20px;
}
.childElement {
	margin: 0 0 20px 20px;
}
.timeBarDays {
	@extend .childElement;
	width: 100px;
}
.worldscaleApplicable {
	@extend .childElement;
	li {
		height: 20px;
	}
}

.maxPortCostChild {
	@extend .childElement;
}
.amount {
	width: 100px;
}
.currency {
	width: 75px !important;
}

@import "variables";
.root {
	li {
		margin-bottom: $gap;
	}
}

.inline {
	display: flex;
	li {
		margin-right: $gap;
	}
	&.reverse {
		li {
			margin-right: 0;
			margin-left: $gap;
		}
	}
}

@import "variables";
.root {
	height: 100%;
	position: relative;
	background-image: url('/components/Loading/loading.svg') center no-repeat 64px;
	background-color: $gray-1;
	display: flex;
	flex-direction: row;
}

.container {
	width: 100%;
	height: 100%;
}

.scrollable {
	overflow: auto;
	height: 100%;
	width: 100%;
	position: relative;
}

.konvaContainer {
	margin: 0 auto;
	position: relative;
}

.content {
	transform-origin: 0 0;
	transition: all 0.3s ease-in-out;
	cursor: -webkit-grab;

	&:active {
		cursor: grabbing;
	}
}

.zoom {
	position: absolute;
	right: 30px;
	bottom: 30px;
	color: $white;
	background-color: rgba(27, 27, 27, 0.9);
	border-radius: 2px;
	min-height: 22px;
	z-index: 1;

	&.panel {
		width: 32px;
		left: $gap;

		border: 1px solid rgba(255, 255, 255, 0.57);
		border-radius: 4px;

		> button,
		.zoomValue {
			padding-top: $gap * 0.5;
			box-sizing: border-box;
			width: 32px;
			height: 32px;
		}

		.zoomValue {
			font-size: 10px;
			border-top: 1px solid rgba(255, 255, 255, 0.57);
			border-bottom: 1px solid rgba(255, 255, 255, 0.57);
		}
	}

	> button {
		border: 0;
		height: auto;
		min-height: 0;
		> i {
			font-size: $icon-size-xs;
		}
	}
}

.content,
.zoom {
	user-select: none;
}

.zoomValue {
	min-width: 32px;
	text-align: center;
	display: inline-block;
}

@import "variables";
.autocomplete {
	:global(.ant-select-arrow) {
		display: none;
	}
}

.highlightOption {
	color: $cyan-1;
	font-weight: bold;
}

@import "variables";
/**
 * Global styles should be here
 */

// Core variables and mixins
@import './variables'; // custom app variables

// Core CSS
@import '~bootstrap-sass/assets/stylesheets/bootstrap/tables';

// Components
@import '~bootstrap-sass/assets/stylesheets/bootstrap/close';

// Utility classes
@import '~bootstrap-sass/assets/stylesheets/bootstrap/utilities';

@import './animations'; // Animations
@import './core';

@import './modules/index';

@import "variables";
.contentWrapper {
	// need to rewrite Content styles here
	padding: $gap-lg 13px 0 13px !important;
}

.tabsContainer {
	border-bottom: $border-base;
}

.tabs {
	:global {
		.ant-tabs-content {
			overflow: hidden;
		}
	}
}

.scrollableLayout {
	// increase negative offset because of non-standard padding above
	margin-right: -13px !important;
	margin-left: -13px !important;
}

.statusSelect {
	min-width: 110px;
	> *[aria-autocomplete='list'] {
		&,
		&:focus {
			border-color: transparent;
		}

		&:focus,
		&:active {
			box-shadow: none;
		}
	}
	i[aria-label^='icon'] {
		color: $main-color;
	}
	:global(.ant-select-selection-selected-value) {
		float: right;
	}
}

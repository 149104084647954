@import "variables";
.tabs {
	/* @diego Not sure if this is the right solution now but I will have the chance to improve it on future PRs */
	height: 100%;
	:global(.ant-tabs-bar) {
		margin-bottom: 0 !important;
	}
}

.eventsList {
	border-left: $border-base;
}

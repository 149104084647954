@import "variables";
.root {
	user-select: none;
	&:global {
		> .ant-radio-button-wrapper {
			color: $primary-color;
			font-weight: $font-weight-semi-bold;
			border: 1px solid $primary-bg;
			border-left: 0;
			&:first-child {
				border-left: 1px solid $primary-bg;
			}
			&[class$='-checked'] {
				background-color: $primary-bg;
				color: $white;
			}
		}
	}
}

@import "variables";
.root {
	width: 100%;
	overflow: auto;
}

.loadingIcon {
	background-image: url('../../../../components/Loading/loading.svg');
	background-position: center;
	background-repeat: no-repeat;
	background-size: 64px;
}

.content {
	transform-origin: 0 0;
	transition: all 0.3s ease-in-out;
	cursor: -webkit-grab;
	padding: 20px 100px;
	filter: drop-shadow(0 42px 18px rgba(0, 0, 0, 0.25));

	&:active {
		cursor: grabbing;
	}
}

.contentCenter {
	transform-origin: center 0;
}

.scrollable {
	overflow: auto;
	width: 100%;
	position: relative;
}

.konvaContainer {
	margin: 20px auto;
	position: relative;
	filter: drop-shadow(0 42px 18px rgba(0, 0, 0, 0.25));
}

.textForm {
	position: absolute;
	border: 2px solid #40a9ff;

	box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
	border-radius: 2px;

	.textTools {
		padding: $gap;
		background-color: $white;
		max-height: $gap-xxl;
		justify-content: space-between;
		box-shadow: inset 0 -1px 0 #f0f0f0;

		* {
			min-width: unset;
		}

		> div.tools {
			padding: 0;
			display: flex;
			align-items: center;

			> div {
				width: 65px;
			}
			> span.divider {
				width: 0;
				height: 12px;
				border: 1px solid rgba(0, 0, 0, 0.06);
				margin-left: $gap;
			}
			> i {
				color: $gray-10;
				opacity: 0.5;
				border-collapse: collapse;
				transition-duration: 0.1s;
				font-size: 15px;
				margin-left: $gap;

				&.active {
					opacity: 1;
					color: $gray-10;
				}

				&:hover {
					opacity: 0.75;
				}

				&[ant-click-animating-without-extra-node]:after {
					display: none;
				}
			}
		}

		div.close {
			padding: 0;

			> i {
				font-size: 15px;
				padding-left: $gap;
			}
		}
	}

	.select {
		width: 65px;
	}

	textarea {
		width: 350px;
		opacity: 0.8;
		border: none;
	}
}

@import "variables";
@mixin asteriks() {
	content: '*';
	color: $error-color;
	display: inline-block;
}

@mixin position-asteriks($position: left) {
	@if $position == left {
		&:before {
			@include asteriks;
			margin-right: $asteriks-offset;
		}
	}

	@if $position == right {
		&:after {
			@include asteriks;
			margin-left: $asteriks-offset;
		}
	}
}

.left {
	@include position-asteriks(left);
}

.right {
	@include position-asteriks(right);
}

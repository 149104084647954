@import "variables";
.glMark {
	display: inline-block;
	padding: 0 $gap * 0.5;
	border: 1px solid $blue-10;
	border-radius: 3px;
	font-size: 10px;
	color: $blue-10;
	cursor: pointer;

	.anticon {
		color: inherit;
	}
}
.glTable {
	tr {
		line-height: $gap-lg;
	}
	td:first-child {
		min-width: 200px;
		color: $gray-2;
	}
	td:last-child {
		width: 150px;
		text-align: left;
	}
	margin-bottom: $gap * 0.5;
}
.glTablePopover {
	width: 420px;
	:global {
		.ant-popover-inner-content {
			max-height: 250px;
			overflow: auto;
		}
	}
}

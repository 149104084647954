@import "variables";
.subHeaderContainer {
	border-bottom: $border-base;
}
.sideBar {
	width: 100%;
	border-bottom: $border-base;
	justify-content: space-between;
	cursor: pointer;
	&:hover {
		background-color: $green-4;
	}
}

.sideBarActive {
	background-color: $green-4;
}

.fileInfoWithDeleteBtn {
	display: flex;
	justify-content: flex-end;
	align-items: center;
	width: 30%;
	> i {
		margin: 0 $gap;
	}
}

.subHeader {
	font-size: 16px;
	font-weight: 600;
}

.tag {
	width: 65px;
	text-align: center;
}

.hide {
	visibility: hidden;
}

@import "variables";
.root {
	&:not(.noTop) {
		padding-top: $gap;
	}

	*[class*='Flex'] > & {
		flex-grow: 1;
	}
}

.fullHeight {
	height: 100%;
}

.sm {
	*:not([class*='flexbox-col']) > & {
		@include make-gutter(column);
	}
}

.md {
	*:not([class*='flexbox-col']) > & {
		@include make-gutter(column, $gap-lg);
	}
}

.lg {
	*:not([class*='flexbox-col']) > & {
		@include make-gutter(column, $gap-xxl);
	}
}

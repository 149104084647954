@import "variables";
.root {
}

.header {
	border-bottom: 1px solid transparent;
}

.footer {
	border-top: 1px solid transparent;
}

.header,
.footer {
	padding: $gap;
}

.primary {
	> .header,
	> .footer {
		background-color: $aside-bg;
		border-color: $border-color;
	}
}

.xs {
	width: 160px;
}
.sm {
	width: 240px;
}
.md {
	width: 320px;
}
.lg {
	width: 480px;
}

.placementLeft {
	border-left: $border-base;
}

.placementRight {
	border-right: $border-base;
}

.scrollable.withSize {
	flex: 1 0 auto !important;
}

@import "variables";
:global {
	.ant-dropdown-menu-item:hover,
	.ant-dropdown-menu-submenu-title:hover {
		background: $background-5;
	}
	.ant-menu-item-disabled {
		border-color: $blue-6 !important;
	}
}

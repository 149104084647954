@import "variables";
.threadHistory {
	position: relative;
	height: 100%;
	overflow-y: auto;
	padding: 20px 15px;
}

.assignToMeAlert {
	position: relative;
	z-index: 2;

	:global {
		.ant-alert {
			position: absolute;
			top: calc(100% + #{$gap-md * 2});
			left: $gap-md * 0.5;
			width: calc(100% - #{$gap-md * 2});
			z-index: 1;
		}
	}
}

.topBorder {
	border-top: 1px solid $border-color;
}

.paddingTop {
	padding-top: 70px;
}

.threadHeader {
	border-bottom: $border-base;
	padding: 0;
	height: 50px;
	position: relative;
}

.threadHeaderStatusUpdated {
	color: $gray-9;
	display: inline-block;
	margin-left: $gap;
	font-size: $font-size-sm;
}

.marginLeft {
	&.threadHeader {
		margin-left: -$gap-md;
	}
}

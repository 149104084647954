@import "variables";
.rowNoBorderBottom {
	&:global(.ant-table-row) > td {
		border-bottom: 0;
	}
}

.creditRow {
	background-color: $background-7;
}

.creditMark {
	position: absolute;
	margin-top: -$gap-md;
	margin-left: -$gap-sm;
}

@import "variables";
.jobItem {
	display: inline-block;
	margin-bottom: $gap-sm;
	&:not(:last-child) {
		margin-right: $gap;
	}
}

.createAppointmentLink {
	width: 170px;
	line-height: $gap-md;
	:global {
		.anticon {
			margin-top: -$gap-md;
		}
	}
	span {
		white-space: break-spaces;
		text-align: left;
	}
}

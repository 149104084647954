@import "variables";
@mixin apply-theme($color) {
	color: $color;
	> sup {
		&:global(.ant-badge-dot) {
			background-color: $color;
		}
	}

	&.adjustIcon {
		:global {
			.anticon {
				color: $color;
			}
		}
	}
}

$badge-size: 16px;
$badge-dot-size: 6px;

.root {
	> sup {
		color: inherit;
		padding: 0;
		font-size: $font-size-sm;
		font-weight: $font-weight-bold;
		transform: none;
		box-shadow: none;
		animation-duration: 0s;

		&:global {
			&:not(.ant-badge-dot) {
				background-color: $white;
				height: $badge-size;
				min-width: $badge-size;
				line-height: $badge-size;
				padding: 0 $gap-xs;
			}
		}
	}

	&.adjustIcon {
		:global {
			.anticon {
				font-size: 18px !important; /* TODO remove !important when icons sizes in `Link` component are verified */
			}
		}
	}

	> button {
		height: auto;
		min-height: 0;
		border: 0;
		padding: 0 !important; /* having inner space will influence `sub` element position */
		margin-right: 0;
	}
}

.success {
	@include apply-theme($success-color);
}

.warning {
	@include apply-theme($warning-color);
}

.error {
	@include apply-theme($error-color);
}

.standard {
	@include apply-theme($standard-color);
}

@mixin apply-offset() {
	&:global {
		&:not(.ant-badge-not-a-wrapper) {
			@content;
		}
	}
}

.topRight {
	@include apply-offset {
		> sup {
			transform: translate(100%);
			&:global(.ant-badge-count) {
				right: 6px;
				margin-top: -4px;
			}
			&:global(.ant-badge-dot) {
				right: -$badge-dot-size * 0.5;
				margin-top: -2px;
			}
		}
	}
}
.bottomRight {
	@include apply-offset {
		> sup {
			top: initial;
			bottom: 0;
			transform: translate(100%, 50%);
			&:global(.ant-badge-count) {
				right: 6px;
				margin-bottom: 4px;
			}
			&:global(.ant-badge-dot) {
				right: -$badge-dot-size * 0.5;
				margin-bottom: -2px;
			}
		}
	}
}

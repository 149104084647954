@import "variables";
@import './Mixins';

@mixin reset($min-height: auto, $line-height: normal) {
	line-height: $line-height;
}

.root {
	/** by default, circle has @font-size-base + 2px */
	font-size: 1rem;
	display: inline-block;
	align-items: center;
	font-weight: 600;
	box-shadow: none;

	@include reset($btn-height-sm, 100%);

	&:global {
		&.ant-btn-circle,
		&.ant-btn-circle-outline,
		&:local(.link).ant-btn-icon-only {
			@include reset;
		}

		&.ant-btn-circle,
		&.ant-btn-circle-outline {
			&:not(.ant-btn-icon-only) {
				width: auto;
				border-radius: 20px;
				padding: 2px 10px;
			}
		}
		> .anticon {
			+ span {
				margin-left: 3px;
			}
			&.anticon-plus,
			&.anticon-minus {
				> svg {
					shape-rendering: auto;
				}
			}
		}
	}

	&:global(.ant-btn-primary) {
		&[class*='ghost'] {
			@include ant-button-variant-ghost(
				$primary-color,
				transparent,
				$primary-color
			);
		}
		&:not([class*='ghost']) {
			@include ant-button-variant($btn-color, $primary-color, $primary-color);
		}
	}

	:global(.ant-tooltip) & {
		pointer-events: none;
	}

	&:global(.ant-btn-icon-only) {
		&.transparent,
		&.thick {
			width: auto;
		}
	}
}

/**
 * Custom Button Size
 */
.buttonXSmall {
	min-height: $btn-height-xs;
}

.transparent {
	&:not(.iconOnly) {
		&:global(.ant-btn) {
			padding-left: 0;
			padding-right: 0;
		}
	}
	&:global(.ant-btn) {
		&:global(.ant-btn-clicked:after) {
			animation: none;
		}
		&,
		&:first-child:not(:last-child),
		&:last-child:not(:first-child),
		&:not(:first-child):not(:last-child) {
			@include ant-button-variant-ghost(
				$icon-color-3,
				transparent,
				transparent
			);
			&[class*='-primary'] {
				@include ant-button-variant-ghost(
					$primary-color,
					transparent,
					transparent
				);
			}
			&[class*='-danger'] {
				@include ant-button-variant-ghost(
					$error-color,
					transparent,
					transparent
				);
			}
		}
	}
	&:global([disabled]) {
		opacity: 0.6;
		&,
		&:hover {
			background: transparent;
		}
	}
}

.standard {
	@include ant-button-variant($btn-color, $standard-color);
}

.disabled {
	&,
	// this is needed for overriding cursor style on opened tooltip
	&:global(.ant-tooltip-open) {
		cursor: not-allowed;
		pointer-events: none;
	}
}

.thick {
	padding-left: 0 !important;
	padding-right: 0 !important;
}

/**
 * Icon Size
 */
.iconOnly {
	display: inline-flex;
	padding-left: 8px;
	padding-right: 8px;
}

.iconLeft,
.iconRight {
	display: flex;
	align-items: center;
}

.iconLeft {
	i {
		margin-right: $gap-sm;
		order: 1;
	}
}
.iconRight {
	i {
		margin-left: $gap-sm;
		order: 2;
	}
}
// for IE, because IE ignores the pointer-events property on inline items
.inlineBlock {
	display: inline-block;
}

/**
	Notification Bubble
	-------------------
	TODO Eventually move to component
 */
@mixin bubble-notification($background) {
	min-height: 15px;
	min-width: 15px;
	border-radius: 15px;
	background-color: $background;
	color: $white;
	white-space: nowrap;
	line-height: 1.1;
	font-size: 9px;
	padding: 0 6px;
	@include flex-justify-align(inline-flex, center);
}

@import "variables";
.tabPane {
	:global {
		.ant-tabs-content {
			overflow-x: hidden;
			max-height: 350px;
		}
	}
}

.vendorTabPane {
	width: 60% !important;
	padding: 1px; /* This padding is given to fix the scrollbar issue in firefox */
}

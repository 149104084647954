@import "variables";
.root {
	:global {
		.ant-btn.ant-btn-loading:not(.ant-btn-circle):not(.ant-btn-circle-outline):not(.ant-btn-icon-only) {
			padding-left: 15px;
		}
	}
}

.clickable {
	cursor: pointer;
}

.body {
	position: relative;
	overflow: hidden;
}

button.toggleBtn {
	&,
	&:not([disabled]):hover,
	&:not([disabled]):focus {
		text-decoration: underline;
	}
}

.primaryType {
	&:not(.collapsed) {
		margin-top: $gap;
	}
}

.linesType {
	&.collapsed {
		.body {
			&:after {
				content: '';
				position: absolute;
				bottom: 0;
				left: 0;
				display: block;
				height: 20px;
				width: 100%;
				background: -webkit-linear-gradient(
					bottom,
					rgba($white, 0.67) 0%,
					rgba($white, 0.67) 50%,
					transparent 100%
				);
			}

			tr:hover > td & {
				&:after {
					background: -webkit-linear-gradient(
						bottom,
						rgba($table-row-hover-bg, 0.67) 0%,
						rgba($table-row-hover-bg, 0.67) 50%,
						transparent 100%
					);
				}
			}
		}
	}
}

.delimiterType {
	.collapseToggle {
		min-height: 0;
		margin-left: 5px;
	}
}

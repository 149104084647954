@import "variables";
.root {
	:global {
		label {
			line-height: 30px;
			float: right;
		}

		.ant-form-item-control {
			line-height: normal;
		}
		.ant-form-item-label {
			line-height: 30px;
		}
		.Select-control {
			height: 30px;
		}
		.Select-placeholder,
		.Select--single > .Select-control .Select-value {
			height: 30px;
			line-height: 30px;
		}
	}
}

.statusSelect {
	width: 100%;
}

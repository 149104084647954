@import "variables";
.root {
	border-top: $border-base;
	margin-top: $gap;
	h4 {
		text-transform: uppercase;
		font-weight: 600;
		font-size: $font-size-sm;
		color: $title-color-1;
		margin-right: $gap;
		// Align title with the first row of tags
		padding-top: $gap-xs;
	}

	.tag {
		margin-right: $gap-sm;
		max-width: 100%;
		white-space: pre-wrap;
		:global {
			.anticon-close {
				font-size: $font-size-xs;
			}
		}
	}
}

.tagsListWrapper {
	margin-top: $gap-sm;
}

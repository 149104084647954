@import "variables";
.root {
	/**
	 * need to reset padding of column class
	 */
	padding: 0 !important;
	background-color: $blue-6;
	border-left: $border-base;
	overflow: auto;
}

.setting {
	width: 100%;
	padding: $gap;
	&:not(:last-child) {
		border-bottom: $border-base;
	}

	h4 {
		@include flex-justify-align(flex, space-between, center);
		color: initial;
		font-weight: 600;
		font-size: 1rem;
		text-transform: uppercase;
		margin-bottom: 8px;
		&.noCaps {
			text-transform: none;
		}
	}
}

.row {
	&:not(:last-child) {
		margin-bottom: $gap-xs;
	}
}

.jobItem {
	display: inline-block;
	margin-bottom: 5px;
	&:not(:last-child) {
		margin-right: $gap;
	}
}

.wrapper {
	padding: 0 $gap-lg;
}

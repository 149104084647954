@import "variables";
.header {
	min-height: 55px;
}

.highlighted {
	background: #fff6e2;
}

.timelineBody {
	width: 100%;
}

:global .view-mode-context-wrapper {
	pointer-events: none;
	opacity: 0.5;
}

:global .view-mode-alert {
	padding: $gap;
}

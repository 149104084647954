@import "variables";
.root {
	top: 0;

	/**
	 * vh (instead of %) units since they responds better to parent max-height
	 * @see https://css-tricks.com/forums/topic/max-height-as-percentage-issues/#post-166185
	 */
	max-height: 90vh;
	:global {
		.ant-modal {
			&-content {
				border-radius: 5px;
				max-height: 90vh;
			}

			&-header {
				border-bottom: 3px solid $border-color-3;
				padding: $modal-title-padding-vertical $modal-padding-horizontal;
			}

			&-title {
				font-size: 15px;
				font-weight: 600;
				color: $title-color-3;
				line-height: 1.5;
			}

			&-close-x {
				width: $modal-close-size;
				height: $modal-close-size;
				font-size: $icon-size-xs;
				line-height: $modal-close-size;
			}

			&-body {
				padding: $modal-padding-vertical $modal-padding-horizontal;
				max-height: 68vh;
				overflow-y: auto;
			}

			&-footer {
				padding: $modal-padding-vertical $modal-padding-horizontal;
			}
		}
	}

	:global {
		.ant-modal-footer {
			@include flex-container;
			@include flex-justify;
		}
	}
}

.onlyText {
	:global {
		.ant-modal-body {
			padding-right: ($modal-padding-horizontal * 2);
		}
	}
}

.withTitle {
	:global {
		.ant-modal-close-x {
			width: $modal-with-title-close-size;
			height: $modal-with-title-close-size;
			line-height: $modal-with-title-close-size;
		}
	}
}

.fullSizeModal {
	height: 100%;
	min-width: 100%;
	max-height: fit-content;
	padding-bottom: 0;
	:global {
		.ant-modal {
			&-content {
				height: 100%;
				max-height: fit-content;
			}
			&-header {
				padding: $gap-sm $gap;
				background: $blue-7;
				border-bottom: none;
			}
			&-title {
				font-size: $font-size;
				font-weight: $font-weight-normal;
				color: $white;
			}
			&-close-x {
				width: $gap-xxl;
				height: $gap-xxl;
				line-height: $gap-xl;
				color: $cyan-1;
			}
			&-body {
				height: 95% !important;
				width: 100%;
				max-height: fit-content;
				overflow: hidden;
			}
		}
	}
}

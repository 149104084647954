@import "variables";
.wrapper {
	background: $blue-6;
	margin: 0 - $gap-sm;
	padding: 0 $gap-sm;
}

.note {
	text-align: center;
	font-size: 10px;
	color: #989ea4;
	padding: $gap-sm;
}

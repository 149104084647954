@import "variables";
.root {
	font-size: $icon-size;
	&:global(.ant-dropdown-trigger) {
		cursor: pointer;
	}
}

.xxs {
	font-size: $icon-size-xxs;
}

.xs {
	font-size: $icon-size-xs;
}

.sm {
	font-size: $icon-size-sm;
}

.md {
	font-size: $icon-size-md;
}

.lg {
	font-size: $icon-size-lg;
}

.xl {
	font-size: $icon-size-xl;
}

.standardColor {
	color: $standard-color;
}

.darkColor {
	// TODO verify
	color: #3b4b5a;
}

.darkBlueColor {
	color: $blue-7;
}

.grayBlueColor {
	color: $blue-11;
}

.primaryColor {
	color: $primary-color;
}

.warningColor {
	color: $warning-color;
}

.errorColor {
	color: $error-color;
}

.successColor {
	color: $success-color;
}

.infoColor {
	color: $info-color;
}

.valignMiddle {
	vertical-align: middle;
}

.offsetRight {
	margin-right: 0.5em;
}

.offsetLeft {
	margin-left: 0.5em;
}

.clickable {
	cursor: pointer;
}

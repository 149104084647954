@import "variables";
.table {
	table-layout: fixed;
	position: relative;
}

.header {
	height: $table-row-height;
	line-height: 1em;
	background: $table-header-dark-bg;
	&:global {
		> td {
			@include make-gutter(column);
			padding-right: 0;
		}
	}
	:global(.ant-badge) {
		margin-left: $gap-xl;
	}
}

.noJobsPlaceholder {
	height: 50px;
	padding: 16px;
	text-align: center;
	color: rgba(0, 0, 0, 0.45);
}

:global(.ant-badge-dot) {
	z-index: 1;
}

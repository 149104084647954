@import "variables";
.root {
	background-color: $background-3;
	border-radius: 10px;
	padding: $gap-lg $gap-xxl;
	margin-top: $gap-lg;

	h2 {
		text-align: center;
		font-weight: $font-weight-bold;
		text-transform: uppercase;
	}
}

@import "variables";
.subscribedStatus {
	background-color: $green-1;
}

.notSubscribedStatus {
	background-color: $orange-1;
}

.putClass {
	background-color: #9b708b;
}

.getClass {
	background-color: #6bbd5b;
}

.postClass {
	background-color: #248fb2;
}

.deleteClass {
	background-color: #e27a7a;
}

.httpRequestClass {
	color: white;
}

.permissionTitle {
	white-space: normal;
	text-align: left;
	min-height: 0;
}

.tag {
	@include bubble-notification($background-1);
	min-width: 35px;
	padding: 2px 6px;
	width: 35px;
}

.header {
	div[class*='flexboxgrid__col'] {
		width: 100%;
		padding-left: 0;
	}
}

.colWidth {
	overflow: visible;
	width: 100%;
	padding-top: $gap-lg;
	margin-left: -$gap-lg !important;
}

.apiGroupPermissionTree {
	position: relative;
	overflow: visible;
	:global {
		.ant-tree-node-content-wrapper {
			width: 100%;
			height: auto !important;
		}
	}
}

.cancelButton {
	&:global.ant-btn {
		padding: 0 $gap;
	}
}

.root {
	margin-left: $gap-xxl;
	margin-top: auto;
	padding-bottom: $gap;
}

:global {
	.tokenSection {
		margin-left: $gap-xxl;
	}

	.reqNewTokenLink {
		margin-left: $gap-xxxl;
		margin-top: $asteriks-offset;
	}

	.copyToken {
		align-items: center;
		margin-left: $gap-xxl;
		display: flex;
		font-weight: $font-weight-semi-bold;
	}
}

.copyIcon {
	align-items: center;
	button {
		border: none;
		height: $gap-md;
		width: $gap-md;
		margin: 0 $gap-sm;
		padding: 0;
		vertical-align: bottom;
		i {
			font-size: $font-size-md;
		}
	}
}

.downloadApiPopover {
	:global {
		.ant-popover-content {
			min-width: 60px;
			.ant-menu-vertical {
				padding-left: 2px;
			}
		}

		.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
			background-color: transparent;
			color: $black-1;
		}
	}
}

.pointer {
	cursor: pointer;
}

.downloadApiPopUp {
	position: absolute;
}

.emdDownloadApi {
	position: relative;
}

.notificationMargin {
	margin-left: $gap;
}

@import "variables";
.root {
	:global(.ant-popover-inner-content) {
		padding: 0 !important;
	}
	:global(.ant-tabs-bar) {
		margin-bottom: 0;
	}

	/**
	 * To prevent using !important, all the selector chain below needs to be covered
	 * ant-table-small > .ant-table-content > .ant-table-body > table
	 */
	:global(.ant-table-body) {
		table {
			padding-left: 0 !important;
			padding-right: 0 !important;
		}
	}
}

.tableWidth {
	/**
     * Took from UI Kit reference
     * @see https://projects.invisionapp.com/d/main#/console/12523415/290637404/preview
     */
	width: 385px;
}

.fullWidth {
	width: 100%;
}

$item-height-sm: ($table-row-padding-vertical * 2) + $input-height;
$table-border-size: 1px;

.tabs {
	overflow: hidden;
}

.fixedHeader {
	:global(.ant-table-body) {
		max-height: $item-height-sm * 5 + ($table-border-size * 2);
		overflow-y: auto;
	}
}

.button {
	span {
		margin-left: 0;
	}
}

@import "variables";
.root {
	margin: $gap-lg 0;
	padding-bottom: $gap;
	background-color: $blue-6;
}

.table {
	width: 100%;
	:global {
		.ant-table-placeholder {
			padding: 6px 16px;
		}
	}
	td {
		background: $white;
	}
}

/**
 * General Layout
 */

html {
	font-size: $font-size-base;
}

html,
body,
#app,
#app > [data-reactroot] {
	height: 100%;
	/**
	* For fixing the drag issue in Vessel Programme 
	*/
	position: relative;
}

body {
	color: $text-color-base;
	font-size: $font-size;
}

a {
	color: $link-color;
	font-size: $font-size-base;
	font-weight: $font-weight-semi-bold;
	line-height: 1.5;

	&:focus {
		text-decoration: none;
	}

	&:hover {
		color: $link-hover-color;
	}
}

table {
	width: 100%;
	border-collapse: collapse;
}

.close {
	font-size: inherit;
}

///
/// Atomic Margin utilities
/// @see https://acss.io/
///
@for $i from 1 through 5 {
	.Mt\(#{$i}em\) {
		margin-top: $i * 1em;
	}
	.Mb\(#{$i}em\) {
		margin-bottom: $i * 1em;
	}
}

// Grid. antd grid styles
div[class*='flexboxgrid__container-fluid'],
div[class*='flexboxgrid__col'] {
	padding-left: $grid-gutter;
	padding-right: $grid-gutter;
}

div[class*='flexboxgrid__row'] {
	margin-left: -$grid-gutter;
	margin-right: -$grid-gutter;
}
.vertical-center-modal {
	display: flex;
	align-items: center;
}

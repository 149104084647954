@import "variables";
.assignToMeAlert {
	position: relative;
	z-index: 2;

	:global {
		.ant-alert {
			top: $gap;
			position: absolute;
			width: calc(100% - #{$gap-md * 2});
			margin: 0 $gap-md;
			z-index: 1;
		}
		.ant-btn {
			min-height: 0;
		}
	}
}

@import "variables";
.section {
	flex: none;
	padding: $gap-sm $gap-lg 0;
}

.sectionContent {
	margin-left: -$gap;
	margin-right: -$gap;
	width: 100%;
}

.division {
	margin-bottom: $gap-sm;
}

.sectionColumn {
	width: auto;
	border-collapse: separate;
	word-wrap: anywhere;

	tr > td {
		padding: $gap-sm 0;

		&:first-child {
			white-space: nowrap;
		}

		&:last-of-type {
			padding-left: $gap-sm;
			font-weight: bold;
		}
	}
}

@import "variables";
.contactInfo {
	word-break: break-all;
	:global {
		.ant-btn {
			opacity: 0;
		}
	}
	&:hover {
		:global {
			.ant-btn {
				opacity: 1;
			}
		}
	}
}

@import "variables";
.deleteGroupModalNote {
	margin: $gap 0;
}

.noBreakOutText {
	overflow-wrap: break-word;
	word-wrap: break-word;
	word-break: break-word;
	hyphens: auto;
}

.bold {
	font-weight: bold;
}

@import "variables";
.root {
	&:not(.none) {
		&.inline {
			@include flex-box(inline-flex);
		}
		&:not(.inline) {
			@include flex-box(flex);
		}
		&.vertical {
			flex-direction: column;
		}
	}

	//By default, flex items won\'t shrink below their minimum content size
	//(the length of the longest word or fixed-size element).
	//‘min-width’ is set to change this
	> * {
		min-width: 0;
	}
}

.fit {
	height: 100%;
	flex-grow: 1;
}

.justifyBetween {
	@include flex-justify;
}

.justifyEnd {
	justify-content: flex-end;
}

.justifyCenter {
	justify-content: center;
}

.justifyAround {
	@include flex-justify(space-around);
}

.alignCenter {
	@include flex-align;
}

.alignStart {
	@include flex-align(flex-start);
}

.alignEnd {
	@include flex-align(flex-end);
}

.alignBaseline {
	@include flex-align(baseline);
}

.stretch {
	@include make-gutter;
}

.selfCenter {
	@include flex-self(center);
}

.selfEnd {
	@include flex-self(flex-end);
}

.row {
	@include make-gutter;
	> * {
		@include make-gutter(column);
	}
}

.wrap {
	flex-wrap: wrap;
}

.grow {
	flex-grow: 1;
}

.noShrink {
	flex-shrink: 0;
}

@import "variables";
.root {
	background-color: #ffe4b6;
	border-color: #ffd183;
	border-radius: 5px;
	max-width: 100%;
	:global {
		ul,
		ol,
		dl {
			margin-bottom: 0;

			& + {
				ul,
				ol,
				dl {
					margin-top: 20px;
				}
			}

			li {
				margin-top: $gap-md;
			}

			.anticon {
				margin-right: $gap-md * 0.5;
			}
		}
	}
}

@import "variables";
.minusBefore::before {
	content: '-';
}

.minusAfter::after {
	content: '-';
	margin-left: $gap-sm;
}

.minusBeforeFormItem {
	:global {
		.ant-form-item-control-wrapper::before {
			content: '-';
			position: absolute;
			top: 50%;
			left: $gap-sm;
			line-height: 1px;
		}
		.ant-form-item-control {
			width: calc(100% - 18px);
			margin-left: auto;
		}
	}
}

.gapRight {
	margin-right: $gap-sm;
}

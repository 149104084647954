@import "variables";
.tabs {
	width: 100%;
	height: 100%;
	overflow: hidden;
	display: flex;
	flex-direction: column;
	:global(.ant-tabs-bar.ant-tabs-top-bar) {
		margin-bottom: 0;
	}
	:global(.ant-tabs-content) {
		/**
		* Height of the tabs header 43px used here for proper height calculation inside the tabPanels
		*/
		height: calc(100% - 43px);
	}
}

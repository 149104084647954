@import "variables";
.duplicateItem {
	margin-left: $gap-xl;
	position: relative;
}

.radioButton {
	position: absolute;
	top: $gap-md;
	left: -$gap-xl;
}

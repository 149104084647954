$white: #fff;
$black: #000;

$black-1: #1b1b1b;
$black-2: #001325;

$gray-1: #333;
$gray-2: #666;
$gray-3: #999;
$gray-4: #ccc;
$gray-5: #71777e;
$gray-6: #ccd3db;
$gray-7: #e8e8e8;
$gray-8: #f5f5f5;
$gray-9: #9b9b9b;
$gray-10: #4a4a4a;
$gray-11: #f2f4f5;

$blue-1: #d6e8f8;
$blue-2: #f7f8f9;
$blue-3: #eaf3fb;
$blue-4: #cdd6dd;
$blue-5: #9aadbb;
$blue-6: #f0f1f3;
$blue-7: #033354;
$blue-8: #1c4765;
$blue-9: #355c76;
$blue-10: #5d53b4;
$blue-11: #688598;
$blue-12: #869aaf;
$blue-13: #338adb;
$blue-14: #3e6681;

$cyan-1: #39a794;
$cyan-2: #b0dcd4;
$cyan-3: #61b9a9;
$cyan-4: #f9d5d9;

$red-1: #e12e3f;
$red-2: #f9d5d9;
$red-3: #fcebed;
$red-4: #e75865;

$green-1: #47b65d;
$green-2: #ecf7ee;
$green-3: #30b9a6;
$green-4: #eff8f6;
$green-5: #38a794;
$green-6: #f5faf9;

$orange-1: #ff8000;
$orange-2: #fff6e2;
$orange-3: #976f49;

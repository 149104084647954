@import "variables";
.root {
	word-break: break-word;
	word-wrap: break-word;

	&:global {
		> .ant-modal-content {
			min-height: 340px;
			@extend %flex-container-column;
			.ant-modal-footer {
				margin-top: auto;
			}
		}
	}
}

.progressContainer {
	margin-top: $gap-lg;
}

.file {
	padding: $gap-sm 7px;
	border: $border-base;
	border-radius: $border-radius;
	line-height: 1.2;

	i {
		font-size: 20px;
		margin-right: $gap;
	}

	.size {
		color: $title-color-1;
	}
}

.fileInfo {
	width: 100%;
}

@import "variables";
.root {
	:global {
		.ant-table table {
			border-collapse: collapse;
		}
	}
}

.section {
	:global {
		.ant-input {
			text-align: right;
		}
	}
}

.infoBar {
	margin: $gap * 0.5 0;
	&:global(.ant-alert-info) {
		padding-right: $gap;
	}
}

.total {
	margin-right: 250px;
	span {
		vertical-align: baseline;
	}
}

.fixedRateDaTotalAmount {
	color: $green-1;
}

.tooltipContainer {
	max-width: 275px;
}

.disabledCell {
	@include disableFinanceTableCell();
	.cannotBeDisabled {
		z-index: 2;
		position: relative;
	}
	.forceDisable {
		@include disableFinanceTableCell(3);
	}
}

.footerRow {
	min-height: $btn-height-sm;
}

.headerIndicateRateCol {
	padding-right: $gap;
}

.moreInfoTitle {
	border-bottom: 1px solid $black;
}

.servicesSection {
	margin-bottom: $gap-xl;
	:global {
		.ant-table {
			.ant-spin-nested-loading,
			.ant-spin-container,
			.ant-table {
				position: static;
			}
		}
	}
}

.columnRightBorder {
	border-right: 2px solid $gray-6;
}

.columnRegularBackground {
	background-color: $background-6;
}

.columnCreditLineBackground {
	background-color: $background-7;
}

.subtotals {
	margin-left: 85px;
}

.rateData {
	margin: 0 $gap;
	min-width: 100px;
}

.finaceAssignedToUserIcon {
	position: inherit;
	padding-left: 2px;
}

.finaceAssignedToUser {
	padding-left: $gap;
}

.inactiveGroupMember {
	color: $red-1;
}

.assignToUser {
	margin-right: $gap;
}

.submitDropdown {
	border-right: 2px solid $white !important;
}

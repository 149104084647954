$tabs-inc-height: 3px;
$tabs-tab-color: $primary-color;

// Theme
$tabs-line-bar-padding: $gap;
$tabs-line-color: $tab-heading-color;
$tabs-line-border-width: 3px;
$tabs-line-border-color: $border-color-3;
$tabs-line-tab-margin-right: $gap-lg;
$tabs-line-tab-padding: 13px 0;

$tabs-card-bar-padding: $gap;
$tabs-card-color: $blue-7;
$tabs-card-height: 33px;
$tabs-card-border-width: 2px;
$tabs-card-border-color: $border-color-1;
$tabs-card-tab-background: $background-4;
$tabs-card-tab-margin-right: 2px;
$tabs-card-tab-padding: 0 $gap-md;

/**
	Style text inside a tab
 */
@mixin tab-variant-text($color: $tabs-tab-color) {
	color: $color;

	&:hover,
	&:focus {
		color: lighten($color, 10%);
	}
}

/**
	Configure base horizontal layout
 */
@mixin tabs-horizontal($border-width, $base-height: 0) {
	> .ant-tabs-bar {
		border-bottom-width: $border-width !important;

		.ant-tabs-nav-container {
			margin-bottom: -$border-width;
		}

		.ant-tabs-ink-bar {
			height: $border-width;
		}

		.ant-tabs-tab {
			border-width: $border-width;
		}
	}

	@if ($base-height > 0) {
		$height: $base-height + ($border-width * 0.5);
		$line-height: $height - ($border-width * 2);
		> .ant-tabs-bar {
			> .ant-tabs-nav-container {
				height: $height;
			}

			.ant-tabs-tab {
				height: 100%;
				line-height: $line-height;
				&[class*='-active'] {
					padding-bottom: $border-width;
				}
			}
		}

		.ant-tabs-extra-content {
			line-height: $line-height;
		}
	}
}

@mixin tabs-layout-horizontal($type: line, $tab-background: null) {
	$bar-padding: $tabs-line-bar-padding;
	$border-color: $tabs-line-border-color;
	$tab-padding: $tabs-line-tab-padding;
	$tab-margin-right: $tabs-line-tab-margin-right;
	$tab-color: $tabs-line-color;

	@if ($type == card) {
		$bar-padding: $tabs-card-bar-padding;
		$border-color: $tabs-card-border-color;
		$tab-color: $tabs-card-color;
		$tab-padding: $tabs-card-tab-padding;
		$tab-margin-right: $tabs-card-tab-margin-right;
	}

	> .ant-tabs-bar {
		padding-right: $bar-padding;
		border-bottom-color: $border-color;

		.ant-tabs-nav-container {
			padding-left: $bar-padding;
			.ant-tabs-tab-prev-icon,
			.ant-tabs-tab-next-icon {
				left: 20% !important;
			}
		}
		.ant-tabs-tab {
			padding: $tab-padding;
			margin-right: $tab-margin-right;

			&[class*='-active'] {
				@include tab-variant-text;
				border-color: $border-color;
				background-color: $white;
			}

			&:not([class*='active']) {
				@include tab-variant-text($tab-color);

				@if ($tab-background) {
					background-color: $tab-background;
					border-color: $tab-background;
				}
			}
		}
	}
}

@mixin tabs-layout-vertical() {
	> .ant-tabs-bar {
		border-right-color: $border-color;
		.ant-tabs-ink-bar {
			left: 0;
			width: $tabs-line-border-width;
		}
	}

	> .ant-tabs-bar,
	> .ant-tabs-bar .ant-tabs-tab {
		background-color: $background-3;
	}

	.ant-tabs-tab {
		margin-bottom: 0;
		border-bottom: $border-base;
		padding: $gap;
		text-align: left;

		&[class*='-active'] {
			@include tab-variant-text;
			background-color: $background-2;
		}

		&:not([class*='active']) {
			@include tab-variant-text($tabs-line-color);
		}
	}
}

@mixin tabs-line($direction: horizontal) {
	@if ($direction == horizontal) {
		@include tabs-horizontal($tabs-line-border-width);
		@include tabs-layout-horizontal;
	}
	@if ($direction == vertical) {
		@include tabs-layout-vertical;
	}
}

@mixin tabs-card() {
	@include tabs-horizontal($tabs-card-border-width, $tabs-card-height);
	@include tabs-layout-horizontal(card, $tabs-card-tab-background);
}

@import "variables";
.root {
	position: relative;
	min-width: $checkbox-offset;
	min-height: $checkbox-width;
	&:global {
		&:hover {
			> .ant-checkbox:after {
				visibility: hidden;
			}
		}
		> .ant-checkbox {
			position: absolute;
			top: 0;
			+ span {
				display: inline-block;
				padding-left: $checkbox-offset;
			}
			> .ant-checkbox-inner {
				@extend %checkbox-inner;
				width: $checkbox-width;
				height: $checkbox-width;
				margin: 1px;
			}
		}
	}
}

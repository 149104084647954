@import "variables";
%remove-styles {
	border: none;
	font-weight: 400;
	text-transform: none;
	font-size: $font-size;
}

$base-color: $background-4;

.label {
	border-radius: 2px;
	border: 1px solid $black;
	font-size: $font-size-xs;
	font-weight: $font-weight-semi-bold;
	text-transform: uppercase;
	color: $text-color-base;
	background-color: $white;
	@include flex-box(inline-flex);
	@include flex-align;
	> span {
		padding: 0 $gap-sm;
		@include flex-box(inline-flex);
		@include flex-align;
	}
	> .statusDot {
		flex-shrink: 0;
	}
	.root > & {
		> span {
			&:nth-child(1) {
				padding: 0 18px;
			}
			&:nth-child(2) {
				padding: 0 14px;
			}
		}
	}
	&.generic {
		text-transform: none;
	}
	&.ghost {
		@extend %remove-styles;
	}
	&.unstyled {
		@extend %remove-styles;
		background-color: transparent;
	}
	&.warning,
	&.primary,
	&.info,
	&.error,
	&.secondary {
		color: $white;
	}
	&.base {
		color: $gray-1;
		background-color: $base-color;
		border-color: $base-color;
	}
	&.warning {
		background-color: $warning-color;
		border-color: $warning-color;
	}
	&.primary {
		background-color: $primary-color;
		border-color: $primary-color;
	}
	&.info {
		background-color: $info-color;
		border-color: $info-color;
	}
	&.secondary {
		background-color: $standard-color;
		border-color: $standard-color;
	}
	&.error {
		background-color: $red-1;
		border-color: $red-1;
	}
}

.timeAgo {
	background-color: $white;
	color: $black;
	text-transform: none;
}

.statusDot {
	width: 8px;
	height: 8px;
	border-radius: 50%;
	display: inline-block;
	vertical-align: middle;

	&.status {
		&-base {
			background-color: $base-color;
		}
		&-standard {
			background-color: $standard-color;
		}
		&-primary {
			background-color: $primary-color;
		}
		&-warning {
			background-color: $warning-color;
		}
		&-success {
			background-color: $success-color;
		}
		&-error {
			background-color: $error-color;
		}
		&-info {
			background-color: $info-color;
		}
		&-secondary {
			background-color: $standard-color;
		}
	}
}

.size-lg {
	height: $page-header-height;
}

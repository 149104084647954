@import "variables";
.alertForSingleGroupUser {
	margin-top: $gap;
}

.noBreakOutText {
	overflow-wrap: break-word;
	word-wrap: break-word;
	word-break: break-word;
	hyphens: auto;
}

// Global variables file
// Every configurable option should be here as a variable or collection of variables

// Bootstrap variables
@import '~bootstrap-sass/assets/stylesheets/bootstrap/variables';

@import './variables/index';

// Utilities
// -------------------------
@import './mixins/index';
@import './utils/functions';
@import './utils/mixins';
@import './placeholders/index';
@import './utils/placeholders';
@import './utils/keyframes';

// Modal
// -------------------------
$modal-title-padding-vertical: $gap-md;
$modal-padding-vertical: $gap;
$modal-padding-horizontal: $gap-lg;
$modal-close-size: 30px;
$modal-with-title-close-size: 50px;

// Forms
// -------------------------
$input-line-height-base: 17px; // non-bootstrap

// Tags
// -------------------------
$tag-default-border: #445564;

// Tabs
// -------------------------
$tabs-card-background: #fff;
$tabs-card-background-off: $gray-6; // #f0f2f3;
$tabs-card-border: #93adc8;
$tabs-card-text: #666;
$tabs-card-text-off: #000;
$tabs-ink-bar-color: $primary-color;

// Job Codes
// -------------------------
$job-code-1: $blue-11;
$job-code-2: $blue-10;
$job-code-3: $red-4;
$job-code-4: $orange-3;
$job-code-5: $blue-5;
$job-code-6: $gray-3;

$job-codes-list: (
	$job-code-1,
	$job-code-2,
	$job-code-3,
	$job-code-4,
	$job-code-5,
	$job-code-6
);

$timeline-gap: 40px;
$timeline-border-width: 2px;
@import '~bootstrap-sass/assets/stylesheets/bootstrap/mixins';

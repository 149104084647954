@import "variables";
@import './TabsLayout';

.customizable {
	&:global {
		&.ant-tabs-line .ant-tabs-tab .anticon {
			color: $icon-color-2;
			font-weight: $font-weight-normal;
		}

		/**
			Horizontal Layout
			-----------------
		 */
		&:not(.ant-tabs-vertical) {
			&.ant-tabs-line {
				@include tabs-line;
			}

			&.ant-tabs-card {
				@include tabs-card;
			}
		}

		/**
			Vertical Layout
			---------------
		 */
		&.ant-tabs-vertical {
			&.ant-tabs-line {
				@include tabs-line(vertical);
			}
		}
	}
}

.root {
	&:global {
		> .ant-tabs-bar {
			.ant-tabs-ink-bar {
				background-color: $tabs-inc-bar-bg;
				width: $tabs-line-border-width;
			}

			.ant-tabs-tab {
				font-weight: $font-weight-bold;
				text-transform: uppercase;
				white-space: normal;
			}
		}

		/**
			Vertical Layout
			---------------
		 */
		&.ant-tabs-vertical {
			> .ant-tabs-bar {
				> .ant-tabs-nav-container,
				> .ant-tabs-nav-container > .ant-tabs-nav-wrap {
					margin-right: 0;
				}
			}
		}
		&.ant-tabs-left {
			.ant-tabs-ink-bar {
				left: 0;
			}

			> .ant-tabs-content {
				padding-left: 0;
				border-left: 0; /* Right border is set on ant-tabs-bar */
			}
		}

		/**
			Horizontal Layout
			-----------------
		 */
		&:not(.ant-tabs-vertical) > .ant-tabs-bar {
			margin-bottom: $gap;

			/* Allow extra content fill out available width */
			@include flex-container(row-reverse);
			@include flex-justify(flex-end);
			flex-shrink: 0;

			> .ant-tabs-extra-content {
				flex-grow: 1;
				padding-left: $gap;
			}
		}

		/* Reset border for nested `card` inside `line` */
		&.ant-tabs-card {
			> .ant-tabs-bar .ant-tabs-tab {
				border-bottom: 0;
			}

			> .ant-tabs-content > .ant-tabs-tabpane-inactive {
				display: none;
			}
		}
	}
}

.gapTop {
	padding-top: $gap;
}

.fit {
	width: 100%;
	height: 100%;

	&:global {
		> .ant-tabs-content {
			flex-grow: 1;
			overflow-y: auto;

			&,
			.ant-tabs-content,
			.ant-tabs-tabpane.ant-tabs-tabpane-active {
				height: 100%;
			}
		}

		&.ant-tabs-left {
			@include flex-container;
		}

		&.ant-tabs-top {
			@include flex-container(column);
		}

		&.ant-tabs {
			&-top,
			&-left {
				> .ant-tabs-bar {
					flex-shrink: 0;
				}
			}
		}
	}
}

.borderless {
	:global {
		.ant-tabs-bar {
			border-bottom-color: transparent !important;
		}
	}
}

@import "variables";
.dragAndDropContainer {
	&:global {
		> .ant-upload {
			background-color: $white;
			border-width: 2px;
			padding: $gap 0;
		}
	}

	i {
		font-size: 18px;
	}
}

.fileInfo {
	border: $border-base;
	border-radius: $border-radius;
	padding: $gap-sm;
	width: 300px;
	line-height: 1.2;
	margin: 0 $gap $gap 0;
	> i {
		font-size: 21px;
	}
}
.deleteFileInfoBtn {
	align-self: flex-start;
	margin-left: auto;
}

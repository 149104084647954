@import "variables";
.disabledWrapper {
	position: relative;

	:global {
		.ant-select-disabled .ant-select-selection__clear {
			display: block;
			visibility: visible;
		}
	}
}

.clearAgent {
	position: absolute;
	display: block;
	right: $gap;
	top: 32px;
	opacity: 1;
	background: transparent;
	&:hover + * {
		:global {
			.ant-select-selection__clear {
				opacity: 1;
			}
		}
	}
}

.field {
	:global(.ant-select-selection-selected-value) {
		padding-right: 0 !important;
	}
}

@import "variables";
.root {
	h1 {
		font-size: 24px;
		font-weight: bold;
		margin-bottom: 20px;
	}

	p {
		font-size: 16px;
	}
}

.icon {
	font-size: 20px;
	vertical-align: middle;
	margin: 0 5px;
}

.hooray {
	background: white;
	border-radius: $border-radius;
	width: 100%;
	.hr {
		width: 33px;
		margin-top: 35px;
	}
	h1 {
		color: $primary-color;
		font-size: 31px;
		font-weight: $font-weight-light;
		margin-top: $gap;
		margin-bottom: 0;
	}
	.lead {
		margin-top: $gap;
		font-size: 16px;
	}
}

@import "variables";
.justifyEnd {
	display: flex;
	justify-content: flex-end;
}

.fullWidth {
	padding-left: $gap;
	padding-right: $gap;
	padding-top: $gap-md;
	min-width: 100%;
	@include respond-to('xs', min) {
		padding-left: ($gap-lg * 2);
		padding-right: ($gap-lg * 2);
	}
}

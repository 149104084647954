@import "variables";
@import 'src/components/antd/Button/Mixins';

.toolbarHead {
	padding-bottom: $gap;
	width: 100%;
	height: 42px;
	justify-content: space-between;

	background: $white;
	z-index: 1;
	box-shadow: 0 5px 5px -2px rgba(0, 0, 0, 0.2);
}

.iconCol {
	justify-content: space-between;
	width: 74vw;

	button {
		width: 32px;
		height: 32px;
		margin-right: $gap;
		box-shadow: 0 2px 0 rgba(0, 0, 0, 0.043);
	}

	.zoomValue {
		display: flex;
		flex-direction: row;
		justify-content: center;
		align-items: center;
		width: 69px;
		height: 32px;
		gap: 10px;
		margin-right: $gap;

		font-weight: 600;
		font-size: 14px;
		line-height: 22px;

		border: 1px solid #d9d9d9;

		box-shadow: 0 2px 0 rgba(0, 0, 0, 0.016);
		border-radius: 2px;
	}

	span.spacer1 {
		width: 100px;
	}

	span.spacer2 {
		width: 40px;
	}
	span.spacer3 {
		width: 32%;
	}
}

.flexButtons {
	width: 25vw;
	margin-right: $gap;

	button {
		height: 32px;
		margin-right: $gap;
	}

	.test {
		display: block;
	}
}

.palettePopover {
	:global(.ant-popover-inner-content) {
		padding: 0;
	}

	* {
		min-width: 0 !important;
	}
}

.primary {
	color: $main-color;
	border-color: $main-color;
}

.danger {
	@include ant-button-variant-danger($error-color, $btn-color, #d9d9d9);
}

.hide {
	visibility: hidden;
}

@import "variables";
.root {
	z-index: 990; /* Puts it behind a modal */

	@at-root :global(.ant-popover-open) {
		cursor: pointer;
	}

	:global {
		.ant-popover-title {
			line-height: 1.3;
			font-size: 15px;
			font-weight: $font-weight-semi-bold;
			padding: $gap-md $popover-padding-horizontal;
			border-bottom: 3px solid $border-color-3;
		}
		.ant-popover-inner-content {
			padding: $gap $popover-padding-horizontal;
		}
	}

	footer {
		@include make-gutter(row, $popover-padding-horizontal);
		@include flex-box;
		@include flex-justify;
		padding: $gap $popover-padding-horizontal 0;
		border-top: 1px solid $border-color-3;
	}
}

.sm {
	width: 270px;
}

.lg {
	width: 380px;
}

.dropdownType {
	margin-top: -12px;
	padding-top: 0;
	border-radius: $border-radius;
	min-width: 0;

	:global {
		.ant-popover-inner {
			box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
		}
		.ant-popover-inner-content {
			padding: 0;
		}

		.ant-menu {
			border-radius: inherit;

			> li.ant-menu-item {
				line-height: normal;
				height: auto;
				padding: 4px $gap;
				border-bottom: 1px solid $border-color-3;
				font-weight: $font-weight-semi-bold;
				margin: 0;

				&:active {
					background-color: transparent;
				}

				&:last-child {
					border-bottom-color: transparent;
				}
			}
		}
	}

	&:global([class*='ant-popover-placement']) {
		&[class*='-bottomRight'],
		&[class*='-bottomLeft'] {
			:global {
				.ant-menu {
					> li {
						text-align: right;
					}
				}

				.ant-popover-arrow {
					top: -4px;
					right: 6px;
				}
			}
		}
		&[class*='-bottomLeft'] {
			:global {
				.ant-menu {
					> li {
						text-align: left;
					}
				}
			}
		}
	}
}

@import "variables";
.collapse {
	border-radius: 0;
	border-left: none;
	border-right: none;
	background-color: $background-2;

	&:global {
		> .ant-collapse-item > .ant-collapse-header {
			font-weight: $font-weight-bold;
			color: $tab-heading-color;
			text-transform: uppercase;
			padding: $collapse-header-padding-vertical
				$collapse-header-padding-horizontal;

			.ant-collapse-arrow {
				font-size: 12px;
				color: $icon-color-5;
				line-height: normal;
				transform: translateY(-50%);
				top: 50%;
			}

			& .ant-collapse-arrow:not(:first-child) {
				transform: none;
			}
		}
	}

	:global(.ant-collapse-content) {
		padding-left: $collapse-header-padding-horizontal;
		padding-right: $collapse-header-padding-horizontal;

		/* shouldn't be hidden to prevent cutting child elements */
		overflow: visible;
	}

	:global(.ant-collapse-content-box) {
		padding: 0;
	}

	h4 {
		font-weight: $font-weight-bold;
	}
}

.noContentPadding {
	&:global {
		> .ant-collapse-item > .ant-collapse-content {
			padding-left: 0;
			padding-right: 0;
		}
	}
}

.collapse.main {
	&:global {
		> .ant-collapse-item {
			> .ant-collapse-header {
				padding: 0 $gap-xxl;
				min-height: 30px;
				line-height: 30px;
				> .ant-collapse-arrow {
					left: $gap;
					font-size: $font-size-xs;
				}
			}
		}
	}
}

.collapse.primary {
	&:global {
		> .ant-collapse-item {
			> .ant-collapse-header {
				text-transform: none;
				color: $text-color-base;

				&,
				.ant-collapse-arrow {
					font-weight: $font-weight-semi-bold;
				}

				.ant-collapse-arrow {
					right: $gap;
					> .anticon {
						margin-left: $gap-sm;
					}
				}
			}
		}

		> .ant-collapse-item > .ant-collapse-content {
			background-color: $white;

			.ant-collapse-content-box {
				padding-top: 0;
			}
		}
	}
}

.collapse.filter {
	border-radius: 0;
	border: none;
	background-color: $white;

	&:global {
		> .ant-collapse-item {
			border: none;
			> .ant-collapse-header {
				font-weight: $font-weight-bold;
				text-transform: none;

				&,
				.ant-collapse-arrow {
					font-size: 12px;
					color: $gray-2;
				}

				.ant-collapse-arrow {
					right: $gap;
					> .anticon {
						margin-left: $gap-sm;
					}
				}
			}
		}

		> .ant-collapse-item > .ant-collapse-content {
			border: none;

			> .ant-collapse-content-box {
				padding-top: 0;
				> .ant-menu {
					border: none;
					> .ant-menu-item {
						padding-left: 0;
					}
				}
			}
		}
	}
}

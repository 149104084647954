@import './colors';

// Fonts
// --------------------------
$font-weight-light: 300;
$font-weight-normal: 400;
$font-weight-medium: 500;
$font-weight-semi-bold: 600;
$font-weight-bold: 700;

$font-size-base: 12px;
$font-size: 1rem;
$font-size-xs: 10px;
$font-size-sm: 11px;
$font-size-md: 14px;
$font-size-xl: 30px;

$h1-font-size: $font-size;
$h2-font-size: $font-size;
$h3-font-size: $font-size;
$h4-font-size: $font-size;
$h5-font-size: $font-size;
$h6-font-size: 11px;

// General
// Margin, padding and other
// --------------------------
$gap: 10px;
$gap-xs: 3px;
$gap-sm: 5px;
$gap-md: 15px;
$gap-lg: 20px;
$gap-xl: 25px;
$gap-xxl: 30px;
$gap-xxxl: 40px;

// Theme Colors
// --------------------------
$base-bg: $blue-4;
$primary-bg: $cyan-1;
$primary-color: $cyan-1;
$success-bg: $green-2;
$success-color: $green-1;
$warning-bg: $orange-2;
$warning-color: $orange-1;
$error-bg: $red-3;
$error-color: $red-1;
$info-bg: $blue-3;
$info-color: $blue-13;
$standard-bg: $blue-12; // for now it is the same as $standard-color until further clarification
$standard-color: $blue-12;
$main-color: $cyan-1;
$default-color: $gray-2;

$gradient: linear-gradient(
	225deg,
	$primary-color 0%,
	#002138 54.27%,
	$black-2 100%
);

// Border
// --------------------------
$border-color: $blue-4;
$border-color-1: $blue-5; // VP, Tabs Card
$border-color-2: $info-color;
$border-color-3: $blue-6;
$border-width: 1px;
$border-radius: 3px;
$border-base: 1px solid $border-color;
// General Colors
// --------------------------
$background-1: $blue-5;
$background-2: $blue-2; // VP Cargo Item, Page Footer, Section Header (VP, Services), Messages Settings, Legend, Events (VP)
$background-3: $blue-6;
$background-4: $blue-4;
$background-5: $green-4;
$background-6: $green-6;
$background-7: $blue-3;
$background-8: $gray-11;
$text-color-base: $black-1;
$headings-color: $text-color-base;
$main-bg: $blue-7;
$aside-bg: $background-3;
// Titles
// --------------------------
$title-color-1: $gray-2;
$title-color-2: $gray-1;
$title-color-3: $black-1;
$tab-heading-color: $gray-2;
// Link
// --------------------------
$link-color: $cyan-1;
$link-hover-color: $cyan-3;
$link-disabled-color: $cyan-2;
// Icon
// --------------------------
$icon-color-1: $blue-11; // $standard-color;
$icon-color-2: $blue-5;
$icon-color-3: $blue-8;
$icon-color-4: $info-color;
$icon-color-5: $primary-color;

$icon-size-xxs: 6px;
$icon-size-xs: 8px;
$icon-size-sm: 10px;
$icon-size-md: 12px;
$icon-size-lg: 15px;
$icon-size-hg: 16px;
$icon-size-xl: 17px;
$icon-size: $icon-size-md;
// Forms
// --------------------------
$input-height: 30px;
$input-placeholder-color: $gray-3;
$input-border-radius: 3px;
$input-border-color: $gray-5;
$input-hover-border-color: $green-5;
$input-focus-border-color: $cyan-1;
$textarea-disabled-bg: $gray-8;
$form-item-label-color: $text-color-base;
$form-item-margin-bottom: $gap-md;
$checkbox-width: 14px;
$checkbox-label-offset: 4px;
$checkbox-offset: $checkbox-width + $checkbox-label-offset;
// Buttons
// --------------------------
$btn-color: $white;
$btn-height: $input-height;
$btn-height-sm: $btn-height;
$btn-height-xs: 20px;
// Feedback
// --------------------------
$alert-message-color: $gray-2;
$alert-description-color: $gray-2;
// Tooltip
// --------------------------
$tooltip-bg: $main-bg;
$popover-padding-horizontal: $gap-lg;
// Tag
$tag-type-gray-background: $gray-4;
// Tables
// -------------------------
$table-header-light-bg: $white;
$table-header-dark-bg: $blue-6;
$table-header-color: $gray-2;
$table-border-color: $blue-4;
$table-row-height: 40px !default;
$table-row-padding-vertical: 8px;
$table-row-padding-horizontal: 8px;
$table-row-hover-bg: $green-4;
$table-row-undread-dot-bg: $primary-color;
// Page Header
// --------------------------
$page-header-height: 30px;
// Tabs
// --------------------------
$tabs-inc-bar-bg: $main-color;
// Sidebar
// --------------------------
$sidebar-inc-bar-bg: $tabs-inc-bar-bg;
$sidebar-bg: $main-bg;
$sidebar-active-bg: $blue-6;
$sidebar-color: $blue-6;
$sidebar-disabled-color: $blue-14;
$sidebar-icon-color: $blue-5;
$sidebar-icon-active-color: $main-color;
// Navbar
// -------------------------
$navbar-height: 50px;
$navbar-primary-bg: $black-2;
// Other
// --------------------------
$transition-speed: 300ms;
$header-bg: $blue-2;
$asteriks-offset: 2px;
$collapse-header-padding-vertical: 8px;
$collapse-header-padding-horizontal: $gap;

// Modals, Hero Panel
// --------------------------
$panel-xs: 320px;
$panel-sm: 360px;
// Grid
// -------------------------
// ** Padding between columns. Gets divided in half for the left and right.
$grid-gutter-width: $gap * 2;
$grid-gutter: $grid-gutter-width * 0.5;
// z-index
$z-index-ten: 10;
$z-index-overlay-default: 1050;

@import "variables";
.root {
	margin: $gap 0;
	thead > tr > th {
		border-bottom: 0;
	}
	:global {
		.ant-table-thead > tr > th {
			padding: $gap-xs $gap;
		}
		.ant-table-tbody > tr > td {
			padding: $gap-sm $gap;
		}

		.ant-table-tbody {
			> tr {
				background: $white;
				&:first-child {
					> td {
						border-top: $border-base;
					}
				}
				> td {
					border-bottom: $border-base !important;

					&:first-child {
						border-left: $border-base;
					}
					&:last-child {
						border-right: $border-base;
					}
				}

				// first row
				&:first-child {
					> td {
						&:first-child {
							border-top-left-radius: $border-radius;
						}
						&:last-child {
							border-top-right-radius: $border-radius;
						}
					}
				}

				// last row
				&:last-child {
					> td {
						&:first-child {
							border-bottom-left-radius: $border-radius;
						}
						&:last-child {
							border-bottom-right-radius: $border-radius;
						}
					}
				}
			}
		}
	}
}

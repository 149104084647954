@import "variables";
@mixin link-variant($initial, $hover, $disabled) {
	color: $initial;

	&:hover {
		color: $hover;
	}

	&.disabled {
		color: $disabled;
		cursor: not-allowed;

		:global {
			.ant-tooltip-open {
				cursor: not-allowed;
			}
		}
	}
}

.standard {
	@include link-variant($link-color, $link-hover-color, $link-disabled-color);
}

.black {
	&,
	&:hover,
	&.disabled {
		color: $black;
	}

	&:hover,
	&.disabled {
		opacity: 0.7;
	}
}

/**
 * Font Weight
 */
.fontWeightRegular {
	font-weight: $font-weight-medium;
}

.fontWeightBold {
	/** It's bold by default since the default value is 600 */
	font-weight: $font-weight-bold;
}

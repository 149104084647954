@import "variables";
.root {
	@include make-gutter(column);
	padding-top: $gap-sm;
	padding-bottom: $gap-sm;
	background-color: $background-2;
}

.stretch {
	@include make-gutter;
}

.borderTop {
	border-top: $border-base;
}

.primary {
	background-color: $white;
}

@import "variables";
.currencySelect {
	max-height: 200px;
	:global(.ant-select-dropdown-menu) {
		max-height: 200px;
	}
}

.timeSaved {
	max-height: 150px;
	:global(.ant-select-dropdown-menu) {
		max-height: 150px;
	}
}

@import "variables";
.editTool {
	position: relative;
	width: 52px;
	height: 100%;
	color: $white;
	background-color: rgba(27, 27, 27, 0.9);
}

.editPanel {
	width: 32px;
	position: relative;
	left: $gap;
	top: $gap;
	border: 1px solid rgba(255, 255, 255, 0.57);
	border-radius: 4px;
}

.editTool i {
	height: 32px;
	padding: 7px $gap * 0.5 $gap * 0.5;
	box-sizing: border-box;

	&:not(:last-child) {
		border-bottom: 1px solid rgba(255, 255, 255, 0.57);
	}
}

.textForm {
	position: absolute;

	.textTools {
		width: $gap * 3;
		padding-left: $gap;
	}

	textarea {
		width: 300px;
		font-size: 30px;
		opacity: 0.8;
	}
}

.palettePopover {
	:global(.ant-popover-inner-content) {
		padding: $gap;
	}
}

.palette {
	.paletteItemWrapper {
		width: 23px;
		height: 23px;
		padding: 3px;
		border: 1px solid transparent;
	}

	.paletteItem {
		width: $gap-md;
		height: $gap-md;
		cursor: pointer;
		border-radius: 100%;
	}

	.blue {
		background-color: $blue-13;
	}

	.green {
		background-color: $green-1;
	}

	.red {
		background-color: $red-1;
	}

	.black {
		background-color: $black-1;
	}

	.orange {
		background-color: $orange-1;
	}

	.gray {
		background-color: $gray-5;
	}

	.selected {
		border: 1px solid $gray-1;
		border-radius: 100%;
	}
}

:export {
	--blueColor: $blue-13;
	--greenColor: $green-1;
	--redColor: $red-1;
	--grayColor: $gray-9;
	--orangeColor: $orange-1;
	--blackColor: $black-1;
}

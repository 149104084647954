@mixin sidebar() {
	background-color: $sidebar-bg;
}

@mixin sidebar-item() {
	background-color: $sidebar-bg;
	text-transform: uppercase;
	font-weight: $font-weight-semi-bold;
	color: $sidebar-color;
	margin: 0 !important;

	.ant-menu-submenu-arrow {
		&::before,
		&::after {
			background: linear-gradient(to right, $sidebar-color, $sidebar-color);
		}
	}

	.anticon {
		color: $sidebar-icon-color;
		font-size: 24px;
		font-weight: $font-weight-normal;
		margin-right: 0;
		margin-bottom: $gap-sm;
	}
}

@mixin sidebar-item-active() {
	background-color: $sidebar-active-bg;

	.anticon {
		color: $sidebar-icon-active-color;
	}
}

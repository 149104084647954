@import "variables";
$bottomWidth: 130px;

%transparent-tooltip {
	transition: none !important;
	background: transparent !important;
}

.uploadAttachment {
	display: flex;
	:global {
		.ant-upload {
			display: inline-block;

			.ant-btn {
				margin-right: $gap-md;
				// overwriting default disabling under the tooltip
				pointer-events: all;
			}

			&-list {
				display: flex;
				align-items: center;
				flex-wrap: wrap;
				margin-bottom: -$gap-md * 0.5;

				&-item {
					display: flex;
					align-items: center;
					margin-top: 0;
					margin-right: $gap-md;
					margin-bottom: $gap-md * 0.5;
					padding-left: $gap-md * 0.5;
					padding-right: $gap-md * 0.5;
					background-color: $background-3;

					&-error {
						background-color: $error-bg;
						.ant-upload-list-item-name {
							color: $error-color;
						}
					}

					.anticon {
						color: $text-color-base;
					}

					// Label
					&-info {
						display: inline-block;
						display: inline-flex;
						align-items: center;
						line-height: 1em;
						@extend %transparent-tooltip;
					}

					// Name inside of label
					&-name {
						display: inline;
						color: $title-color-1;
					}
				}
			}
		}

		.ant-upload-list-item-progress {
			left: 0;
		}

		.ant-tooltip {
			@extend %transparent-tooltip;
		}
	}
}

.root {
	max-height: 100px;
	margin: 5px 0 0;
}

@import "variables";
.buttonWrapper {
	cursor: not-allowed;
	> button {
		pointer-events: none;
	}
}

.draftVendorsTable {
	tr:hover > td {
		background: none;
	}
	tr > td,
	thead > tr > th > span {
		color: $red-1;
	}
	thead > tr > th > span {
		font-weight: $font-weight-bold;
	}
}

:global {
	.ant-modal-mask {
		top: $modal-with-title-close-size;
	}
	.ant-modal-wrap {
		top: $modal-with-title-close-size;
	}
}

.extendedMessagingModal {
	:global {
		.ant-modal-body {
			height: 450px;
			overflow: hidden;
		}
	}
}

@import "variables";
.disabledButton {
	pointer-events: none;
}
.settingButtonPopover {
	:global {
		.ant-popover-content {
			min-width: 60px;
		}

		.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
			background-color: transparent;
			color: $black-1;
		}
	}
}
@mixin settingButton() {
	margin: 0 $gap-md;
	padding: 0 !important;

	&::before {
		background-color: transparent;
	}

	:global {
		.anticon {
			margin-left: 0 !important;
			position: relative;
			left: 3px;
		}
	}
}

.settingButton {
	@include settingButton();
}

.visibilityHidden {
	:global .ant-popover-content {
		visibility: hidden;
	}
}

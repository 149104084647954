////
/// _mixins.scss
/// @group Mixins
/// @author [Diego Martin Marmol](http://twitter.com/dmmarmol)
////

// ===============================================
// BACKGROUND
// ===============================================

/// Background color with fallbacks y pollyfils
/// @group Background Mixins
@use "sass:math";

@mixin background($color, $alpha: 1) {
	$rgba: rgba(0, 0, 0, 0);
	@if $color != transparent {
		$rgba: rgba($color, $alpha);
	}
	$ie-hex-str: ie-hex-str($rgba);
	background-color: $rgba;
	filter: progid:DXImageTransform.Microsoft.gradient(
			startColorstr=#{$ie-hex-str},
			endColorstr=#{$ie-hex-str}
		);
	zoom: 1;
}
// GRADIENT BACKGROUND
// @group Background Mixins
// @param {map} $settings - Gradient settings
// @mixin gradient($from, $to, $direction: to bottom) {
@mixin gradient(
	$settings: (
		e: e
	)
) {
	@if (isMap($settings)) {
		@if (function-exists(map-push)) {
			$settings: map-push(
				(
					from: (
						color: #000
					),
					to: (
						color: #fff
					),
					direction: to bottom
				),
				$settings
			);
		}
	}
	$from: map-get($settings, from);
	$from-color: map-get($from, color);
	$to: map-get($settings, to);
	$to-color: map-get($to, color);
	$start: 0%; // Could be overriden
	$end: 100%; // Could be overriden
	@if (keyExist($from, start)) {
		$start: map-get($from, start);
	}
	@if (keyExist($to, end)) {
		$end: map-get($from, end);
	}
	filter: progid:DXImageTransform.Microsoft.gradient(
			startColorstr='#{$from-color}',
			endColorstr='#{$to-color}'
		);
	background: $from-color; /* Fallback */
	background: -webkit-linear-gradient(
		map-get($settings, direction),
		$from-color $start,
		$to-color $end
	);
	background: -moz-linear-gradient(
		map-get($settings, direction),
		$from-color $start,
		$to-color $end
	);
	background: -o-linear-gradient(
		map-get($settings, direction),
		$from-color $start,
		$to-color $end
	);
	background: -ms-linear-gradient(
		map-get($settings, direction),
		$from-color $start,
		$to-color $end
	);
	background: linear-gradient(
		map-get($settings, direction),
		$from-color $start,
		$to-color $end
	);
}
// RADIAL GRADIENT
// @group Background Mixins
@mixin radial-gradient($from, $to) {
	background: -moz-radial-gradient(center, circle cover, $from 0%, $to 100%);
	background: -webkit-gradient(
		radial,
		center center,
		0px,
		center center,
		100%,
		color-stop(0%, $from),
		color-stop(100%, $to)
	);
	background: -webkit-radial-gradient(center, circle cover, $from 0%, $to 100%);
	background: -o-radial-gradient(center, circle cover, $from 0%, $to 100%);
	background: -ms-radial-gradient(center, circle cover, $from 0%, $to 100%);
	background: radial-gradient(center, circle cover, $from 0%, $to 100%);
	background-color: $from;
}
// ===============================================
// ANIMATIONS
// ===============================================
// Enables Hardware Acceleration
// @group Animation Mixins
// @param integer $perspective
@mixin enableHardwareAcceleration($perspective: 1000) {
	backface-visibility: hidden;
	perspective: $perspective;
}
// Number of times that an animation should be repeated
// @group Animation Mixins
@mixin animation-count($times) {
	animation-iteration-count: $times;
}
// Calculate the images aspect ratio
// @group Mixins
// @example
// The image/video needs to be inside a container
// @include the @mixin in the container and asing
// an element to be affected in the second argument.
// @param {int} $value | Custom value in percentage
// @param {string} $element | The <tag> element to be affected by the aspect ratio
@mixin aspect-ratio($value, $element: false) {
	// Ratios
	$widescreen: calculate-ratio(16, 9); // 56.25%
	$traditional: calculate-ratio(4, 3); // 75c% // Wrapper
	position: relative;
	&:before {
		padding-top: $value;
		content: '';
		display: block;
	}
	// Content
	@if $element != false {
		#{$element} {
			position: absolute;
			top: 0;
			left: 0;
			bottom: 0;
			right: 0;
			width: 100%;
			height: 100%;
		}
	} @else {
		> * {
			position: absolute;
			top: 0;
			left: 0;
			bottom: 0;
			right: 0;
			width: 100%;
			height: 100%;
		}
	}
}
// Calculate the aspect ratio
// @group Mixins
@function calculate-ratio($num1, $num2: false) {
	@return math.div($num2, $num1) * 100%;
}
// ======================================
// TEXT
// ======================================
// TEXT STROKE
// @group Text Mixins
@mixin text-stroke($stroke-width, $stroke-color: false, $stroke-fill: auto) {
	-webkit-text-stroke-width: $stroke-width;
	-webkit-text-stroke-color: $stroke-color;
	-webkit-text-fill-color: $stroke-fill;
}
// Strikethrough Text
// @group Text Mixins
@mixin strikethrough(
	$width,
	$height: 1px,
	$color: hsl(0, 0, 97%),
	$element: '&'
) {
	position: relative;
	#{$element} {
		display: inline-block;
	}
	#{$element}:before,
	#{$element}:after {
		// border-top: 1px solid black;
		background-color: $color;
		display: block;
		height: $height;
		content: ' ';
		width: $width;
		position: absolute;
		left: 0;
		top: 50%;
		margin-top: -$height;
	}
	#{$element}:after {
		right: 0;
		left: auto;
	}
}
// Cuts a certain line of text and adds an ellipsis at the end
// @group Text Mixins
@mixin line-clamp($line) {
	display: -webkit-box;
	-webkit-line-clamp: $line;
	-webkit-box-orient: vertical;
	text-overflow: -o-ellipsis-lastline;
	overflow: hidden;
}
// Cuts a single line of text and add ellipsis
// @group Text Mixins
@mixin truncate-line($activated: true) {
	@if $activated == true {
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	} @else {
		white-space: initial;
		overflow: visible;
		text-overflow: initial;
	}
}
// Maximo de lineas visibles en un texto
// @group Text Mixins
@mixin max-lines($num-of-lines, $line-height: inherit, $ellipsis: false) {
	line-height: $line-height;
	max-height: $num-of-lines * strip-units($line-height) * 1em;
	overflow: hidden;
	@if ($ellipsis) {
		@include line-clamp($num-of-lines);
	}
}
// ======================================
// MEDIA QUERIES
// ======================================
// @group Media Query Mixins
// @param {string} $rules | Traditional list of rules applied to the media query
@mixin screen($rules) {
	// @debug 'media query';
	@media only screen and #{$rules} {
		@content;
	}
}
// $netbook: 600px;
// @group Media Query Mixins
@mixin netbook() {
	@include screen('(max-height: 600px)') {
		@content;
	}
}
// Mobile
// @group Media Query Mixins
@mixin mobile($width: 739px) {
	@media only screen and (max-width: $width) {
		@content;
	}
}
// Media query para maximo en altura
// @group Media Query Mixins
@mixin max-screen-height($height) {
	@include screen(
		(
			max-height: $height
		)
	) {
		@content;
	}
}
// Media query para capturar un espectro entre dos (2) anchos
// Between measures
// @group Media Query Mixins
@mixin media-between($min-width, $max-width: 1680px) {
	@media only screen and ($min-width) and ($max-width) {
		@content;
	}
}
// Bootstrap Helpers for Media Queries managment
// @group Media Query Mixins
// @param {string} $breakpoint | Bootstrap name for viewport ['xsmall'/'xs', 'small'/'sm', 'medium'/'md', 'large'/'lg']
// @param {string} $limit [null] | Max or Min width
@mixin respond-to($breakpoint, $limit: null) {
	@if $breakpoint == 'xsmall' or $breakpoint == 'xs' {
		// @warn "Respond to #{$screen-xs-max}";
		@if ($limit) {
			@media (#{$limit}-width: #{$screen-xs-max}) {
				@content;
			}
		} @else {
			@media (min-width: #{$screen-xs-min}) {
				@content;
			}
		}
	}
	@if $breakpoint == 'small' or $breakpoint == 'sm' {
		// @warn "Respond to #{$screen-sm-max}";
		@if ($limit) {
			@media (#{$limit}-width: #{$screen-sm-max}) {
				@content;
			}
		} @else {
			@media (min-width: #{$screen-sm-min}) {
				@content;
			}
		}
	}
	@if $breakpoint == 'medium' or $breakpoint == 'md' {
		// @warn "Respond to #{$screen-md-max}";
		@if ($limit) {
			@media (#{$limit}-width: #{$screen-md-max}) {
				@content;
			}
		} @else {
			@media (min-width: #{$screen-md-min}) {
				@content;
			}
		}
	}
	@if $breakpoint == 'large' or $breakpoint == 'lg' {
		// @warn "Respond to #{$screen-lg-min}";
		@if ($limit) {
			@media (#{$limit}-width: #{$screen-lg-min}) {
				@content;
			}
		} @else {
			@media (min-width: #{$screen-lg-min}) {
				@content;
			}
		}
	}
}
// Set a Z-index order based on the elements position in array
@mixin z($list) {
	@each $z in $list {
		#{$z} {
			z-index: z($list, '#{$z}');
		}
	}
}
// ========================================
// FLEXBOX
// ========================================
@mixin flex-box($display: flex) {
	display: $display;
}
@mixin flex-container($direction: row) {
	display: flex;
	flex-direction: $direction;
}
@mixin flex-justify($justify: space-between) {
	justify-content: $justify;
}
@mixin flex-align($align: center) {
	align-items: $align;
}
/* *
	@mixin flex-self
	Possible values:
		auto (default),
		stretch,
		center,
		flex-start,
		flex-end,
		baseline,
		initial,
		inherit */
@mixin flex-self($self: auto) {
	align-self: $self;
}
@mixin flex-justify-align(
	$display: flex,
	$justify: flex-start,
	$align: center
) {
	display: $display;
	justify-content: $justify;
	align-items: $align;
}
@mixin flex-center-x-y() {
	display: flex;
	align-content: center;
	justify-content: center;
}
// /
// Holy Grid CSS
// @see https://philipwalton.github.io/solved-by-flexbox/demos/holy-grail/
// /
@mixin holy-grid-container() {
	display: flex;
	min-height: 100%;
	flex-direction: column;
}
@mixin holy-grid-body {
	display: flex;
	flex: 1;
}
@mixin holy-grid-content {
	flex: 1;
}
// Components styles
@mixin style-bottom-shadowed() {
	box-shadow: 0 3px 10px -1px rgba(0, 0, 0, 0.2);
}
@mixin make-gutter($type: 'row', $gutter: $grid-gutter) {
	@if $type == 'row' {
		margin-left: -$gutter;
		margin-right: -$gutter;
	}
	@if $type == 'column' {
		padding-left: $gutter;
		padding-right: $gutter;
	}
}
@mixin customize-arrow-color($color) {
	&:global(.ant-popover-placement) {
		&-top,
		&-topLeft,
		&-topRight {
			:global(.ant-popover-arrow) {
				background-color: $color;
			}
		}
		&-left,
		&-leftTop,
		&-leftBottom {
			:global(.ant-popover-arrow) {
				background-color: $color;
			}
		}
		&-right,
		&-rightTop,
		&-rightLeft {
			:global(.ant-popover-arrow) {
				background-color: $color;
			}
		}
		&-bottom,
		&-bottomLeft,
		&-bottomRight {
			:global(.ant-popover-arrow) {
				background-color: $color;
			}
		}
	}
}
@mixin user-select($value: none) {
	user-select: $value;
	-ms-user-select: $value;
	-moz-user-select: $value;
	-webkit-user-select: $value;
}

@mixin disableFinanceTableCell($zIndex: 1) {
	position: relative;
	&:after {
		content: '';
		position: absolute;
		top: -150px;
		bottom: -200px;
		left: -14px;
		right: -14px;
		background: rgba($background-2, 0.7);
		z-index: $zIndex;
	}
}

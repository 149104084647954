@mixin button-state($color, $background, $border) {
	color: $color;
	background-color: $background;
	border-color: $border;
}

@mixin ant-button-disabled($color, $background, $border) {
	&[disabled] {
		&,
		&:hover,
		&:focus,
		&:active {
			@include button-state($color, $background, $border);
		}
	}
}

@mixin ant-button-variant($color, $background, $border: $background) {
	@include button-state($color, $background, $border);

	&:hover,
	&:focus {
		@include button-state(
			$color,
			lighten($background, 12%),
			lighten($border, 12%)
		);
	}

	&:active {
		@include button-state(
			$color,
			darken($background, 12%),
			darken($border, 12%)
		);
	}

	@include ant-button-disabled(
		$color,
		rgba($background, 0.5),
		lighten($border, 25%)
	);
}

@mixin ant-button-variant-ghost(
	$color,
	$background: transparent,
	$border: $background
) {
	@include button-state($color, $background, $border);
	&:hover,
	&:focus {
		@include button-state(
			lighten($color, 12%),
			$background,
			lighten($border, 12%)
		);
	}

	&:active {
		@include button-state(
			darken($color, 12%),
			$background,
			darken($border, 12%)
		);
	}

	@include ant-button-disabled(
		lighten($color, 25%),
		$background,
		lighten($border, 25%)
	);
}

@mixin ant-button-variant-danger($color, $background, $border) {
	@include button-state($color, $background, $border);
	&:hover,
	&:focus {
		@include button-state(
			lighten($error-color, 12%),
			$background,
			lighten($error-color, 12%)
		);
	}

	&:active {
		@include button-state(
			darken($error-color, 12%),
			$background,
			darken($error-color, 12%)
		);
	}

	@include ant-button-disabled(lighten($color, 25%), $background, $border);
}

@import "variables";
.overlay {
	// should be higher than ant-select-dropdown
	z-index: $z-index-overlay-default + 1;
}

.wrapper {
	@include flex-box(inline-flex);
	@include flex-align(center);
	width: 100%;

	> span {
		overflow: hidden;
		text-overflow: ellipsis;
	}
}

.alignCenter {
	@include flex-container;
	@include flex-align(center);
}

.table {
	margin-bottom: $gap * 0.5;

	tr {
		line-height: $gap-lg;
	}

	td {
		width: 120px;
		text-align: left;

		&:first-child {
			color: $gray-2;
		}
	}

	:global {
		.ant-tooltip-inner {
			white-space: nowrap;
		}
	}
}

.paddingTop {
	padding-top: $gap-sm;
}

.copyToClipboardBtn {
	padding: 0;
	border: 0;
}

@import "variables";
.editContactForm {
	padding: $gap-md $gap-xl 0;
}

.companyBox {
	&:after {
		content: ' ';
		display: block;
		position: absolute;
		left: $gap;
		top: -$gap-sm;
		border-color: $background-3;
		border-width: $gap-sm;
		border-style: solid;
		transform: rotate(45deg);
	}
}

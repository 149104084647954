@import "variables";
// File Item
// --------------------------
$fileItem-default-height: 40px;
$fileItem-border-radius: $border-radius;
$fileItem-border-color: $blue-4;
$fileItem-icon-size: 24px;
$fileItem-spacing: 5px;

.root {
	width: 100%;
	border-radius: $fileItem-border-radius;
	border: 1px solid $fileItem-border-color;
	padding: $fileItem-spacing;
	background-color: #fff;
}

.isClickeable {
	cursor: pointer;
	transition: box-shadow $transition-speed ease,
		transform $transition-speed ease;
	&:hover {
		box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.2);
	}
	&:active {
		box-shadow: 0 0 1px 0 rgba(0, 0, 0, 0.4);
		transform: translateY(2px);
	}
}

.icon {
	font-size: $fileItem-icon-size !important; /* !important Needed to overwrite the default 'sm' value from Icon.tsx */
	line-height: $fileItem-icon-size !important;
}

/* From FileExtension in 'app-constants' */
.ext-pdf {
	color: $red-1;
}
.ext-doc {
	color: $blue-13;
}
.ext-docx {
	color: $blue-13;
}
.ext-xls {
	color: $green-1;
}
.ext-xlsx {
	color: $green-1;
}
.ext-document,
.ext-generic {
	color: $black-1;
}

.info {
	min-width: 100%;
	opacity: 1;
	transition: opacity $transition-speed ease;
}
.isLoading {
	.info {
		opacity: 0.5;
	}
}

.fileNameWrapper {
	display: flex;
	flex-direction: row;
	width: 100%;
}

.rightColWrapper {
	width: 100%;
}

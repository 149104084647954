@import "variables";
.btnGroup {
	display: inline-flex;

	:global(.ant-btn) {
		&.submitBtn {
			border-top-right-radius: 0;
			border-bottom-right-radius: 0;
		}
		&.iconBtn {
			border-top-right-radius: 3px;
			border-bottom-right-radius: 3px;
		}
	}
}

@import "variables";
$timeline-icon-width: 24px;

.root {
	position: relative; // position vertical line relative to the whole container

	*:not(.item) > & {
		margin: $gap 0;
		> .item {
			// Lets assume first and last list items behave predictably - have timeline inside :)
			&:first-child,
			&:last-child {
				.line,
				&.line {
					&:after {
						content: '';
						width: $timeline-border-width;
						position: absolute;
						background-color: $border-color-1;
						height: 100%;
						margin-left: -$timeline-gap * 0.5;
						top: 0;
						bottom: 0;
					}
				}
			}
			// hide line for the last child
			&:last-child {
				&.line,
				> .line {
					position: relative;
					&:after {
						left: 0;
						background-color: $white;
					}
				}
			}
		}
	}

	.root {
		width: 100%;
		> .item {
			padding-bottom: 0;
		}
	}
}

.pull {
	margin-left: -$gap;
}

.push {
	&:global {
		& > * {
			position: relative;
			left: 370px;
		}
	}
}

.item {
	@include flex-box;
	&:not(:last-child) {
		padding-bottom: $gap;
	}

	&.offset {
		padding-top: 30px;
		.element {
			margin-top: -30px;
		}
	}
}

.element {
	position: absolute;
}

.line {
	flex-grow: 1;
	min-height: 30px;
	margin-left: $timeline-gap;
	width: calc(100% - #{$timeline-gap});
}

.icon {
	position: absolute;
	z-index: 2;
	left: 0;
	width: $timeline-icon-width;
	margin-left: -($timeline-gap * 0.5 + $timeline-icon-width * 0.5 -
				$timeline-border-width * 0.5);
	background-color: $white;
	text-align: center;
	padding: 3px 0;

	i {
		font-size: 22px;
	}
}

.placeholder {
	flex-grow: 1;
	min-height: 30px;
	margin-left: $timeline-gap;
}

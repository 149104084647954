@import "variables";
.root {
	background-color: transparent;
	border-radius: $border-radius;
	padding: $gap-md $gap-lg;

	a {
		font-weight: $font-weight-bold;
	}
}

.title {
	font-weight: $font-weight-bold;
}

.center {
	text-align: center;
}

.success {
	background-color: $success-bg;
}

.warning {
	background-color: $warning-bg;
}

.info {
	background-color: $info-bg;
}

.standard {
	background-color: $background-8;
}

.error {
	background-color: $error-bg;
}

.xs {
	width: $panel-xs;
}

.sm {
	width: $panel-sm;
}

@import "variables";
.root {
	max-width: 550px;
	margin: 0 auto;
	text-align: center;
	@extend %animated;
	animation-name: fallbackScreenFadeInDown;

	.status {
		font-size: 70px;
		font-weight: 300;
		color: $blue-7;
	}

	img {
		max-width: 400px;
		margin-bottom: $gap-lg;
	}
	hr {
		width: 33px;
		height: 2px;
	}
	h1 {
		color: $primary-color;
		font-size: 32px;
		font-weight: $font-weight-light;
		margin-top: $gap-md;
		margin-bottom: $gap-xxl;
	}
	.lead {
		margin-top: $gap;
		font-size: 16px;
		line-height: 2;
		a {
			font-size: inherit;
		}
	}
}

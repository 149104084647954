@import "variables";
.addLinkElement {
	:global(.ant-popover-inner-content) {
		padding: $gap;
	}
}

.addLinkElementContent {
	width: 250px;
	display: flex;
	align-items: center;
	padding: $gap $gap * 0.5;
}

.addLinkElementIcon {
	margin: 0 $gap * 0.5;
}

.imageElementActive {
	outline: 2px solid $cyan-1;
}

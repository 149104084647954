@import "variables";
.root {
	&:global(.lines-type) {
		> .anticon {
			translate: transform $transition-speed ease ($transition-speed * 0.5);
		}
		&:global(.collapsed) {
			> button {
				visibility: hidden;
			}
			.anticon {
				transform: rotate(360deg);
			}

			&:hover {
				> button {
					visibility: visible;
				}
			}
		}
	}
}
.toggleBtn {
	.root:global(.lines-type) & {
		display: block;
		width: calc(100% + #{$gap * 2});
		margin: 0 (-$gap) (-$gap);
		font-size: $font-size-xs;
		font-weight: 600;
		text-transform: uppercase;
		padding: 4px 0 5px;
		cursor: pointer;
		z-index: 1;
		border-radius: 0 0 5px 5px;

		&,
		&:hover,
		&:focus {
			text-decoration: none;
			color: $cyan-1;
		}

		> span {
			@include flex-box(inline-flex);
			@include flex-align;
		}

		:global {
			.anticon {
				margin-left: 4px;
			}
		}
	}
}

.showControls {
	&:global(.lines-type) {
		&:global(.collapsed) {
			> div {
				&:after {
					background: transparent;
				}
			}
			> button {
				visibility: visible;
			}
			&:hover {
				> button {
					visibility: visible;
				}
			}
		}
	}
}

@import "variables";
.showMore {
	button {
		text-decoration: none !important;
	}
	div {
		&:after {
			background: none !important;
		}
	}
}

.showMoreIndicator {
	font-weight: normal;
}

@import "variables";
.wrapper {
	overflow: hidden;
}

.row {
	margin-top: $gap;
}

.header {
	color: #1b1b1b;
	font-size: 20px;
	font-weight: 600;
}

.tab {
	width: 100%;
	height: 100%;
	overflow: hidden !important;
	display: flex;
	flex-direction: column;
	:global {
		.ant-tabs-tabpane {
			background-color: #f0f2f5;
		}
	}
	&:global {
		> .ant-tabs-bar {
			.ant-tabs-tab {
				text-transform: none;
				width: 50px;
				text-align: center;
			}
		}
	}
	:global(.ant-tabs-content) {
		/**
		* Height of the tabs header 43px used here for proper height calculation inside the tabPanels
		*/
		height: calc(100% - 52px);
	}
}

.root {
	width: 100%;
	height: 100%;
	justify-content: stretch;

	* {
		min-width: fit-content;
	}
}

.documentContainer {
	width: 100%;
	height: 100%;
	overflow: hidden;
	justify-content: space-between;
	* {
		min-width: 0;
	}

	.documentWrapper {
		width: 74vw;
		justify-content: center;
		overflow: auto;
	}

	.sideWrapper {
		width: 25vw;
		overflow: hidden;
		margin: $gap $gap $gap 0;
		border-radius: 2px;
		background-color: white;
	}
}

.fullWidth {
	width: 100%;
}

@import "variables";
.jobsTab {
	height: 100%;
	width: 100%;
	display: flex;
	align-content: stretch;
}

.jobs {
	width: 70%;
	background: $blue-6;
	padding: $gap;
	overflow-y: auto;
	height: 100%;
	display: flex;
	flex-direction: column;
}
.header {
	margin-bottom: $gap;
	min-height: 30px;
}

.summary {
	padding: $gap;
	height: 100%;
	width: 30%;
}

.addButton {
	margin-right: $gap;
}

.jobsList {
	overflow-y: auto;
	overflow-x: hidden;
}

@import "variables";
.upper {
	text-transform: uppercase;
}

// Decoration
.decorationUnderline {
	text-decoration: underline;
}

.decorationLineThrough {
	text-decoration: line-through;
}

// Font Weight
.lightWeight {
	font-weight: $font-weight-light;
}

.semiBoldWeight {
	font-weight: $font-weight-semi-bold;
}

.normalWeight {
	font-weight: $font-weight-normal;
}

.mediumWeight {
	font-weight: $font-weight-medium;
}

.boldWeight {
	font-weight: $font-weight-bold;
}

.default {
	color: $gray-2;
}

.primary {
	color: $primary-color;
}

.error {
	color: $error-color;
}

.warning {
	color: $warning-color;
}

.success {
	color: $success-color;
}

.info {
	color: $info-color;
}

.silent {
	color: $gray-1;
}

.disabled {
	color: $gray-9;
}

.main {
	color: $main-color;
}

.xs {
	font-size: $font-size-xs;
}

.sm {
	font-size: $font-size-sm;
}

.md {
	font-size: $font-size-md;
}

.breakLines {
	white-space: pre-line;
}

.breakWordInIE {
	display: block;
	width: 100%;
	word-break: break-all;
}

.nowrap {
	white-space: nowrap;
}

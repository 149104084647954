@import "variables";
.root {
	border-radius: $border-radius;
	border: 2px solid transparent;
	max-width: 100%;

	> .content {
		:global {
			.ant-table-thead {
				box-shadow: inset 0 -2px 0 0 $border-color;
			}

			.ant-table-tbody tr:last-child > td,
			.ant-table-placeholder {
				border-bottom: none;
			}
		}
		> footer {
			padding: 0 $gap;
			border-top: $border-base;
		}
	}
}

.standard {
	border-color: $border-color-1;

	> .header {
		padding: 6px 10px;
		background-color: $background-2;
		border-bottom: $border-base;
	}
}

.movement {
	> .header {
		border-radius: $border-radius $border-radius 0 0;
		text-transform: uppercase;
		padding: $gap-sm $gap;
		background-color: $background-4;
		+ .content {
			border-top: 0;
			border-top-left-radius: 0;
			border-top-right-radius: 0;
		}
	}

	> .content {
		border: 2px dashed $border-color;
		border-radius: $border-radius;
	}
}

@import "variables";
.root {
	height: 100%;

	:global {
		.ant-table {
			&-header {
				background-color: $table-header-light-bg;
			}

			&-thead > tr > th,
			&-tbody > tr > td {
				border-bottom-color: $gray-6;
			}

			&-thead {
				> tr > th {
					padding: $gap-sm $gap 4px;
					line-height: 1.6;
					font-weight: 600;
					color: $table-header-color;
					background: transparent;
					border-bottom-width: 2px;
				}
			}

			&-tbody {
				> tr > td {
					padding: 10px $gap;
					word-break: break-word;

					.anticon {
						font-weight: 600;
						vertical-align: middle;
					}
					// Actions
					&:global(.actions) {
						text-align: right;
						:global {
							.ant-btn {
								padding: 0;
								border: 0;
								line-height: 0;
								height: auto;
								margin-right: 5px;
								&:last-child {
									margin-right: 0;
								}
							}
						}
					}
				}

				> tr:hover > td {
					background-color: $table-row-hover-bg;
				}
			}

			// scrolling updates
			&-fixed-header {
				:global {
					.ant-table-scroll {
						@extend %flex-container-column;

						.ant-table-header {
							flex-shrink: 0;
							overflow: hidden !important;
							margin-bottom: 0 !important;
						}

						.ant-table-body {
							flex: 1;
							overflow-y: auto !important;
							max-height: none !important;
						}
					}
				}
			}
		}
	}

	:global {
		.ant-spin-nested-loading,
		.ant-spin-container,
		.ant-table,
		.ant-table-content,
		.ant-table-scroll {
			height: 100%;
		}

		.ant-table-placeholder .ant-empty {
			margin: 0;
			.ant-empty-image {
				display: none;
			}
		}

		/**
 		* Filters
		 */
		.ant-table-thead {
			> tr > th {
				.ant-table-header-column .ant-table-column-sorters:hover::before {
					background-color: transparent;
				}

				:global {
					.ant-table-filter-icon {
						font-size: $icon-size-sm;
						font-weight: $font-weight-semi-bold;
						padding: 0 $gap-sm;
						@include flex-justify-align(flex, center);

						&:hover {
							background: transparent !important;
						}

						&.ant-table-filter-selected {
							> i {
								color: $primary-color;
							}
						}
					}
				}
			}
		}
		.ant-table-column-sorter {
			.anticon-caret-up,
			.anticon-caret-down {
				font-size: 11px !important;
				height: 8px !important;
			}
			.anticon-caret-down {
				margin-top: -2px !important;
			}
			.ant-table-column-sorter-down,
			.ant-table-column-sorter-up {
				overflow: hidden;
			}
		}
	}
}

.isExpandedRowStatic {
	:global {
		tr.ant-table-expanded-row td > .ant-table-wrapper {
			margin: 0;
		}
	}
}

.headerShadowed {
	:global {
		.ant-table-header {
			position: relative;
		}
	}
}

.hideHeaderOfEmptyTable {
	:global {
		.ant-table-empty {
			.ant-table-thead {
				display: none;
			}

			.ant-table-placeholder {
				text-align: center;
				width: 100%;
				border-bottom: none;
			}
		}
	}
}

.hidePlaceholderOfEmptyTable {
	:global {
		.ant-table-empty .ant-table-placeholder {
			display: none;
		}
	}
}

@mixin action-state($color) {
	:global {
		.anticon {
			color: $color;
		}
	}
}

/**
 * Forces row background to be white
 */
.whiteRows {
	:global {
		.ant-table-row {
			background-color: $white;
		}
	}
}

.filtered > i {
	color: $primary-color;
}

.cursorPointer {
	:global {
		.ant-table-row {
			cursor: pointer;
		}
	}
}

@import "variables";
.root {
	:global {
		.ant-table table {
			border-collapse: collapse;
		}
	}
}

.savingContent {
	padding: 0 !important;
	.savingsHeader {
		padding: 6px;
	}
	.addSavingsBtnRow {
		margin-bottom: $gap-xxl;
		margin-right: 0;
		margin-left: 0;
		padding-bottom: $gap-sm;
		padding-top: $gap;
		border-bottom: 1px solid $blue-4;
		.savingsTotalCol {
			line-height: 30px;
			padding-right: 110px;
		}
	}
}

.indicativeRate {
	margin: 0 $gap-sm;
}

@import "variables";
.root {
	color: $black;
	margin-bottom: 0;
}

.gridTitle {
	font-size: 12px;
	text-transform: uppercase;
	color: $table-header-color;
	font-weight: bold;
	padding: $gap 0;
}

.labelTitle {
	font-size: $font-size-sm;
}

.subjectTitle {
	position: relative;
	font-weight: 600;
	.unreadDot {
		position: absolute;
		left: -$gap;
		top: 0;
		bottom: 0;
		margin: auto;
	}
}

.hint {
	font-weight: 600;
	font-size: 14px;
	margin-bottom: 8px;
}

.h1 {
	font-weight: 700;
	font-size: 12px;
}

.h2 {
	font-weight: 600;
	font-size: 12px;
}

.h4 {
	@include flex-justify-align(flex, space-between, center);
	color: initial;
	font-weight: 600;
	font-size: 1rem;
	text-transform: uppercase;
	margin-bottom: 8px;
}

.ellipsis {
	width: 100%;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.subjectTitleUnread {
	color: $primary-color;
}

.unreadDot {
	background-color: $table-row-undread-dot-bg;
	width: 6px;
	height: 6px;
	border-radius: 50%;
	display: inline-block;
	vertical-align: middle;
}

/** Clickeable */
.isClickeable {
	cursor: pointer;
}

/** Types */
.primary {
	color: $primary-color;
}
.success {
	color: $success-color;
}
.warning {
	color: $warning-color;
}
.error {
	color: $error-color;
}
.info {
	color: $info-color;
}
.standard {
	color: $standard-color;
}

// Headings
// ---------------
h1,
h2,
h3,
h4,
h5,
h6 {
	color: $text-color-base;
}

h1 {
	font-size: $h1-font-size;
}
h2 {
	font-size: $h2-font-size;
}
h3 {
	font-size: $h3-font-size;
}
h4 {
	font-size: $h4-font-size;
}
h5 {
	font-size: $h5-font-size;
}
h6 {
	font-size: $h6-font-size;
}

hr {
	border-color: $border-color;
	border-width: 0;
	border-bottom-width: 1px;
}

// Lists
// ---------------
ul,
ol {
	margin: 0;
	padding: 0;
	list-style-type: none;
	&.clean-list {
		list-style: none;
		padding: 0;
	}
}

@import "variables";
.simpleCollapse {
	&:global(.ant-collapse) {
		background-color: transparent;
	}
	:global {
		.ant-collapse-item {
			border-bottom: 0 !important;
		}
		.ant-collapse-header[role='tab'] {
			background-color: transparent;
			&:hover {
				background-color: transparent;
			}
			&:active {
				background-color: transparent;
			}
			text-transform: none;

			i.arrow::before {
				font-size: 14px;
				color: $icon-color-5;
			}
		}
		.ant-collapse-header[role='tab'][aria-expanded='true'] {
			i.arrow::before {
				content: '\e078';
			}
		}
		.ant-collapse-content.ant-collapse-content-active[role='tabpanel'] {
			border-top: 0;
		}
	}
}

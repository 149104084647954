@import "variables";
.root {
	width: 320px;

	.title {
		padding: $modal-title-padding-vertical $modal-padding-horizontal;
		box-shadow: inset 0 -3px 0 0 $blue-6;
	}

	.rowItem {
		padding: 7px $gap-lg;
	}

	.disabledRow {
		background-color: $blue-2;
		cursor: not-allowed;
		opacity: 0.6;
	}

	.filterField {
		padding: $gap-md $gap-lg;
	}

	:global {
		.ant-list-item {
			border-bottom: 0;
		}

		.ant-popover-title {
			padding: $gap-md $gap-lg;
			font-size: 15px;
			font-weight: 600;
			line-height: 20px;
			border-bottom: none;
			box-shadow: inset 0 -3px 0 0 $blue-6;
			color: $black-1;
		}

		.ant-popover-inner-content {
			padding: 0;
		}

		.ant-list-split {
			max-height: 160px;
			overflow-y: auto;
			border-top: 1px solid $gray-6;

			.ant-list-item {
				padding: 0;
				line-height: 16px;

				&:focus,
				&:hover {
					background-color: $green-4;
				}
			}
		}

		.ant-input-search {
			.ant-input::placeholder,
			.ant-input-suffix {
				color: $gray-3;
			}

			.ant-input {
				border-color: $gray-2;
			}
		}

		*[class*='Loading'] {
			padding: $gap;
			> img {
				margin: 0 auto;
			}
		}
	}
}

.list {
	:global {
		.ant-list-item {
			cursor: pointer;
		}
	}
}

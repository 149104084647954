@import "variables";
@mixin tooltip-state($color, $background, $border: $background) {
	&:global {
		&.ant-tooltip {
			word-break: break-word;
			white-space: normal;

			/* Arrow Color */
			&-placement-top,
			&-placement-topLeft,
			&-placement-topRight {
				.ant-tooltip-arrow {
					border-top-color: $border;
				}
			}

			&-placement-right,
			&-placement-rightTop,
			&-placement-rightBottom {
				.ant-tooltip-arrow {
					border-right-color: $border;
				}
			}

			&-placement-bottom,
			&-placement-bottomLeft,
			&-placement-bottomRight {
				.ant-tooltip-arrow {
					border-bottom-color: $border;
					right: $gap;
				}
			}

			&-placement-left,
			&-placement-leftTop,
			&-placement-leftBottom {
				.ant-tooltip-arrow {
					border-left-color: $border;
				}
			}

			/* Arrow Placement */
			&-placement-topRight,
			&-placement-bottomRight {
				.ant-tooltip-arrow {
					right: $gap;
				}
			}
			&-placement-topLeft,
			&-placement-bottomLeft {
				.ant-tooltip-arrow {
					left: $gap;
				}
			}
		}
	}

	:global {
		.ant-tooltip-inner {
			color: $color;
			background-color: $background;
		}
	}
}

.root {
	// should be less than datepicker and modal
	z-index: 1001;

	@at-root :global(.ant-tooltip-open) {
		cursor: pointer;
	}

	:global {
		.ant-tooltip-inner {
			padding: 6px $gap;
			font-weight: $font-weight-light;
		}
	}
}

/**
 * Themes
 */
.main {
	@include tooltip-state($white, $main-bg);
}

.warning {
	@include tooltip-state($text-color-base, $warning-bg);
}

.error {
	@include tooltip-state($text-color-base, $error-bg);
}

@import "variables";
.root {
	> header {
		h2 {
			font-weight: 700;
		}
	}
}

.content {
	white-space: pre-line;
}

.header {
	width: 100%;
}
